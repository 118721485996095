import React, {useEffect, useState} from 'react';
import { useAddNewStudentMutation, useGetStudentsQuery, useUpdateStudentClassesMutation, selectStudentById } from '../students/studentsApiSlice';
import { useGetUsersQuery, useUpdateUserChildrenMutation } from '../users/usersApiSlice';
import { useGetClassesQuery, useAddNewClassMutation, useUpdateClassStudentsMutation } from '../classes/classesApiSlice';
import { useDispatch } from 'react-redux';

function ImportCSV() {
  const [parent_id, setParent_id] = useState([]);
  const [index, setIndex] = useState(0);
  const [enrolledClasses, setEnrolledClasses] = useState([]);
  const [doneAdding, setDoneAdding] = useState(false);
  const dispatch = useDispatch();
  const { data: users} = useGetUsersQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch} = useGetStudentsQuery(undefined, {
    pollingInterval: 100,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [student, setStudent] = useState([]);

  const { data: classes} = useGetClassesQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [addStudent, {
    isLoading,
    isSuccess,
    isError,
    error,
    reset
}] = useAddNewStudentMutation();

const [addClass, {
  isLoading: classAddLoading,
  isSuccess: classAddSuccess,
  isError: classAddErrorBool,
  error: classAddError,
}] = useAddNewClassMutation();

const [updateStudentClasses, {
  isLoading: studentLoading,
  isSuccess: studentSuccess,
  isError: studentErrorBool,
  error: studentError,
}] = useUpdateStudentClassesMutation();

const [updateClassStudents, {
  isLoading: classStudentsUpdateLoading,
  isSuccess: classStudentsUpdateSuccess,
  isError: classStudentsUpdateErrorBool,
  error: classStudentsUpdateError,
}] = useUpdateClassStudentsMutation();

const [updateChild, {
  isLoading: hi,
  isSuccess: hi2,
  isError: hi3,
  error: hi4
}] = useUpdateUserChildrenMutation();

useEffect(() => {
  const lalala = async () => {
      await refetch();
      const { ids: students, entities: stud_ent } = studs;
      const { ids: userIds, entities: user_ent} = users;
      for (const studentId of students) {
        if (studentId && stud_ent[studentId].parent_id && user_ent && !((user_ent[stud_ent[studentId].parent_id].children_ids).includes(studentId))){
          await updateChild({
            user_id: stud_ent[studentId].parent_id,
            child_id: studentId,
            action: "add"
          });
  
          for (let l=0; l<enrolledClasses.length; l++) {
            if (enrolledClasses[l].first_name === stud_ent[studentId].first_name && enrolledClasses[l].last_name === stud_ent[studentId].last_name && enrolledClasses[l].email === stud_ent[studentId].email) {
              for (const element of enrolledClasses[l].enrolled_classes) {
                const response = await updateStudentClasses({
                  student_id: studentId,
                  class_id: element,
                  action: "add"
                });
                const hi = await updateClassStudents({
                  class_id: element,
                  student_id: studentId,
                  action: "add"
                });
              }
            }            
          }
          // setEnrolledClasses([]);
          reset();
        }
      }
  }
  if (studs) lalala();
}, [enrolledClasses])

const processStudentData = (i, jsonData) => {
  // Parse enrolled classes
  let student_enroll = jsonData[i].enrolled_classes;
  let enrolled_classesx = [];
  while (student_enroll.includes("/")) {
    enrolled_classesx.push(student_enroll.slice(0, student_enroll.indexOf("/")));
    student_enroll = student_enroll.slice(student_enroll.indexOf("/") + 1);
  }
  enrolled_classesx.push(student_enroll);
  let enrolled_classes = [];
  const { ids: classIds, entities: class_entities} = classes;
  const { ids, entities } = users;
  const { ids: students, entities: stud_ent } = studs;
  // Turn the enrolled_classes into their IDs
  const class_ids = classIds?.map(classId => {
    for (let k=0; k < enrolled_classesx.length; k++) {
      if (class_entities[classId].group_identifier === enrolled_classesx[k]) {
        return 1;
      }
    }
    return 0;
  });

  for (let k=0; k<class_ids.length; k++) {
    if (class_ids[k] === 1) {
      enrolled_classes.push(classIds[k]);
    }
  }

  const newStudent = {
    first_name: jsonData[i].first_name,
    last_name: jsonData[i].last_name,
    email: jsonData[i].email,
    enrolled_classes: enrolled_classes,
  };

  // Find parent_id using map
  const parent_ids = ids?.map(userId => {
    if (jsonData[i].email === entities[userId].email) {
      const student_match = students?.map(studentId => {
        if (jsonData[i].email === stud_ent[studentId].email && jsonData[i].first_name === stud_ent[studentId].first_name && jsonData[i].last_name === stud_ent[studentId].last_name) {
          return "1";
        } else {
          return "0";
        }
      });
      for (let k = 0; k < student_match.length; k++) {
        if (student_match[k] === 1) {
          return "0";
        }
      }
      return userId;
    }
    return "0";
  });

  let boolasd = false;
  let parentId = 0;
  let parentIds = parent_id;
  for (let j = 0; j < parent_ids.length; j++) {
    if (parent_ids[j] !== "0") {
      parentId = parent_ids[j];
      boolasd = true;
      parentIds.push(parent_ids[j]);
      setParent_id(parentIds);
    }
  }
  if (!boolasd) {
    parentIds.push(0);
    setParent_id(parentIds);
    parentId = 0;
  }
  // (TODO) Get the ID's of the enrolled classes (but need to delete users first)
  // (TODO) Add the student to the class as well
  if (parentId !== 0) {
    let go = true;
    for (const student of students) {
      if (stud_ent[student].first_name === jsonData[i].first_name && stud_ent[student].last_name === jsonData[i].last_name && stud_ent[student].email === jsonData[i].email) {
        go = false;
      }
    }

    if (go) {
      return {parentId, newStudent, addStudentData: jsonData[i]};
      // await processStudent(parentId, enrolled_classes, i, jsonData);
    }
    
  }
}

if (!studs) return <p> Loading... </p>;

const handleProcessStudents = async () => {
  /*
  const { ids: students, entities: stud_ent } = studs;
    for (let i = 0; i<students.length; i++) {
      const stud_ids = students?.map(student_Id => {
        if (stud_ent[student_Id] !== undefined && jsonData[i] !== undefined) {
          if (stud_ent[student_Id].email === jsonData[i].email) {
            if (stud_ent[student_Id].phone === jsonData[i].phone) {
              if (stud_ent[student_Id].first_name === jsonData[i].first_name) {
                if (stud_ent[student_Id].last_name === jsonData[i].last_name) {
                  return student_Id;
                }
              }
            }
          }
        }
        return 0;
      })
  
      let studentId = 0;
      for (let j=0; j<stud_ids.length; j++) {
        if (stud_ids[j] !== 0) {
          studentId = stud_ids[j];
        }
      }

      if (studentId !== 0){
        await updateChild({
          user_id: stud_ent[studentId].parent_id,
          child_id: studentId,
          action: "add"
        });

        // May have async issues since enrolledClasses is global?
        for (let l=0; l<enrolled_classes.length; l++) {
          await updateStudentClasses({
            student_id: studentId,
            class_id: enrolled_classes[l],
            action: "add"
          });
          await updateClassStudents({
            class_id: enrolled_classes[l],
            student_id: studentId,
            action: "add"
          });
        }
      }
      
      reset();
  }
  */
}

/*const processStudent = async (parentId, enrolled_classes, i, jsonData) => {
  await addStudent({
    first_name: jsonData[i].first_name,
    last_name: jsonData[i].last_name,
    parent_id: parentId,
    email: jsonData[i].email,
    phone: jsonData[i].phone
  });  
  await refetch();
  const { ids: students, entities: stud_ent } = studs;
    if (isSuccess) {

    for (let i = 0; i<students.length; i++) {
      const stud_ids = students?.map(student_Id => {
        if (stud_ent[student_Id] !== undefined && jsonData[i] !== undefined) {
          if (stud_ent[student_Id].email === jsonData[i].email) {
            if (stud_ent[student_Id].phone === jsonData[i].phone) {
              if (stud_ent[student_Id].first_name === jsonData[i].first_name) {
                if (stud_ent[student_Id].last_name === jsonData[i].last_name) {
                  return student_Id;
                }
              }
            }
          }
        }
        return 0;
      })
  
      let studentId = 0;
      for (let j=0; j<stud_ids.length; j++) {
        if (stud_ids[j] !== 0) {
          studentId = stud_ids[j];
        }
      }

      if (studentId !== 0){
        await updateChild({
          user_id: stud_ent[studentId].parent_id,
          child_id: studentId,
          action: "add"
        });

        // May have async issues since enrolledClasses is global?
        for (let l=0; l<enrolled_classes.length; l++) {
          await updateStudentClasses({
            student_id: studentId,
            class_id: enrolled_classes[l],
            action: "add"
          });
          await updateClassStudents({
            class_id: enrolled_classes[l],
            student_id: studentId,
            action: "add"
          });
        }
      }
      
      reset();
    }
  }
}
*/

const processClasses = async (clss) => {
  const {ids: classIds, entities: classEntities} = classes;
  const {ids, entities} = users;
  let teacher = "";
  let level = clss.group_identifier.substr(-3, 3);
  const classExist = classIds.map(id => {
    if (classEntities[id].group_identifier !== clss.group_identifier) {
      return 0;
    }
    else {
      return 1;
    }
  })
  let goBool = true;
  for (let i=0; i<classExist.length; i++) {
    if (classExist[i] === 1) {
      goBool = false;
    }
  }
  const teacherIds = ids.map(id => {
    if (entities[id].role === "Teacher" && entities[id].first_name === clss.teacher) {
      return id;
    }
    return 0;
  })
  let teacherId = 0;
  for (const id of teacherIds) {
    if (id !== 0) teacherId = id;
  }
  if (goBool) {
    const response = await addClass({
      group_identifier: clss.group_identifier,
      level: level,
      teacher: clss.teacher,
      timing: clss.timing,
      teacher_id: teacherId
    });  
  }
  
}

  const handleConvertClickClasses = (e) => {
    e.preventDefault();
    const csvFileInput = document.getElementById('csvFileInput');
    const file = csvFileInput.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const csvData = event.target.result;
        const jsonData = convertCSVtoJSONDates(csvData);

        for (let i = 0; i < jsonData.length; i++) {
          processClasses(jsonData[i]);
        }

        // Download the JSON file
        // downloadJSON(jsonData);
      };

      reader.readAsText(file);
      alert('Completed.');
    } else {
      alert('Please select a CSV file to convert.');
    }
  };

  const handleConvertClickStudents = (e) => {
    e.preventDefault();
    const csvFileInput = document.getElementById('csvFileInput');
    const file = csvFileInput.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const csvData = event.target.result;
        const jsonData = convertCSVtoJSON(csvData);
        
        setStudent(jsonData); // Store the array of students in the state
        let studentsToAdd = [];

        for (let i = 0; i < jsonData.length; i++) {
          const currentIndex = i; // Create a local copy of the index
          setIndex(currentIndex); // Update the state with the current index
          let temp = processStudentData(currentIndex, jsonData);
          if (temp) studentsToAdd.push(temp);
        }
        for (const studentData of studentsToAdd) {
          const operation = await addStudent({
            first_name: studentData.addStudentData.first_name,
            last_name: studentData.addStudentData.last_name,
            parent_id: studentData.parentId,
            email: studentData.addStudentData.email,
            phone: studentData.addStudentData.phone
          });
        }
        await refetch();
        let studentEnrolledClasses = [];
        for (const studentData of studentsToAdd) {
          studentEnrolledClasses.push(studentData.newStudent);
        }
        await refetch();
        setEnrolledClasses(studentEnrolledClasses);
        
        // Link to parent
        
        // Download the JSON file
        // downloadJSON(jsonData);
      };      

      reader.readAsText(file);
    } else {
      alert('Please select a CSV file.');
    }
  };

  const convertCSVtoJSON = (csvData) => {
    // Converting the headers and lines from the .csv file to arrays
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');
    for (let i = 0; i < headers.length; i++) {
      headers[i] = headers[i].replace(/\r/g, '');
    }
    for (let i = 0; i < lines.length; i++) {
      lines[i] = lines[i].replace(/\r/g, '');
    }
    const jsonData = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(',');
      if (currentLine.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
        jsonData.push(obj);
      }
    }

    return jsonData;
  };

  const convertCSVtoJSONDates = (csvData) => {
    // Converting the headers and lines from the .csv file to arrays
    const jsonData = [];
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');
    for (let i = 0; i < headers.length; i++) {
      headers[i] = headers[i].replace(/\r/g, '');
    }
    for (let i = 0; i < lines.length; i++) {
      lines[i] = lines[i].replace(/\r/g, '');
    }

    for (let i = 1; i < lines.length ; i++) {
      const currentLine = lines[i].split(',');
      const obj = {};
        for (let j = 0; j < headers.length; j++) {
          if (j === 2) {
            // Push all dates into timing string
            obj[headers[j]] = "";
            for (let k = j; k < currentLine.length-1; k++) {
              obj[headers[j]] += (currentLine[k] + ", ");
            }
            obj[headers[j]] += (currentLine[currentLine.length-1]);
          }
          else obj[headers[j]] = currentLine[j];
        }
        jsonData.push(obj);
      }
    return jsonData;
  };

  const downloadJSON = (jsonData) => {
    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.json';
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  return (
    <>
    <div class = "whitespace"></div>
    <div class = "register">
      <form>
      <input class = "fancy-box" type="file" id="csvFileInput" />
      <button class = "two-buttons login-button" onClick={handleConvertClickStudents}>Import Students</button>
      <button class = "two-buttons login-button" onClick={handleConvertClickClasses}>Import Classes</button>
      </form>
    </div>
    <div class = "whitespace"></div>
    <p class = "centered">Please wait at least one minute after pressing the button to add students to ensure everything is added before leaving the page.</p>
    </>
    
  );
}

export default ImportCSV;