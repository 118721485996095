import React, { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectStudentById, useGetStudentsQuery } from '../students/studentsApiSlice';
import { selectUserById } from '../users/usersApiSlice';
import Class from './Class';

const ViewClasses = () => {
  const { id, username, role } = useAuth();
  const parent = useSelector((state) => selectUserById(state, id));

  const { data: classes, isLoading: bruh, isSuccess: pleaseWORK, refetch } = useGetStudentsQuery(undefined, {
    pollingInterval: 100,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [ids, setIds] = useState([]);
  const [entities, setEntities] = useState({});
  const [makeup, setMakeup] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(''); // Initialize without a value
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [parsedStudentId, setParsedStudentId] = useState(null);
  const [student_names, setStudentNames] = useState([]); // Define student_names here

  const handleStudentSelect = (event) => {
    const selectedStudentName = event.target.value;
    setSelectedStudent(selectedStudentName);

    // Find the corresponding student ID
    const stud_id = ids.find(id => {
      return (entities[id].first_name + ' ' + entities[id].last_name) === selectedStudentName;
    });

    if (!stud_id) {
      return;
    }

    setSelectedStudentId(stud_id);
    setParsedStudentId(parseInt(stud_id.toString().substr(-3, 3), 16)); // Convert to a number
  };

  useEffect(() => {
    if (!classes || classes.isLoading || !parent || !parent.children_ids || !classes.entities) {
      // Data is still loading or classes is undefined or doesn't have entities property
      return;
    }
    const { ids, entities } = classes;
    const studentIds = ids
      .map((id) => {
        for (const element of parent.children_ids) {
          if (element === id) {
            return element;
          }
        }
        return '0';
      })
      .filter((element) => element !== '0');
    const initialStudId = studentIds[0];
    if (initialStudId) {
      setSelectedStudentId(initialStudId);
      setParsedStudentId(parseInt(initialStudId.toString().substr(-3, 3), 16)); // Convert to a number
    }

    setEntities(entities);
    setIds(ids);
  }, [classes, parent]);

  useEffect(() => {
    if (ids.length > 0) {
      const studentNames = ids
        .map((id) => {
          for (const element of parent.children_ids) {
            if (element === id) {
              return entities[id].first_name + ' ' + entities[id].last_name;
            }
          }
          return '0';
        })
        .filter((element) => element !== '0');

      setStudentNames(studentNames); // Set student_names here
      setSelectedStudent(studentNames[0] || ''); // Set the initial value to the first option
    }
  }, [ids]);

  useEffect(() => {
    // Update the background color whenever parsedStudentId changes
    if (parsedStudentId !== null) {
      const container = document.querySelector(".attendance-records");
      const gradientColors = ['#ff6b6b', '#ffae44', '#66c2ff', '#66ff66'];
      const colorIndex = parsedStudentId % gradientColors.length;
      const backgroundColor = gradientColors[colorIndex];
      container.style.background = `linear-gradient(45deg, ${backgroundColor}, ${backgroundColor})`;
    }
  }, [parsedStudentId]);

  if (!classes || classes.isLoading || !parent || !parent.children_ids || !classes.entities) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    selectedStudentId ?
      (
        <div>
          <h1>Welcome, {username}</h1>
          <div>
            <label htmlFor="studentSelect">Select a Student:  </label>
            <select id="studentSelect" onChange={handleStudentSelect} value={selectedStudent}>
              {student_names.map((studentName, index) => (
                <option key={index} value={studentName}>
                  {studentName}
                </option>
              ))}
            </select>
          </div>
          <br />
          <div className="attendance-records">
            <Class studentId={selectedStudentId} />
          </div>
        </div>
      ) : 
      (
        <div>
          <h2>No students found registered under this parent.</h2>
        </div>
      )
  );
};

export default ViewClasses;