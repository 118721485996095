import { useRef, useState, useEffect } from "react"
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectClassById, useUpdateClassMutation } from './classesApiSlice'
const NAME_REGEX = /[0-9]{3}-[A-Z]{3}-[A-Z]{3}-.{3}/i;

const EditClass = () => {
    const { classId } = useParams();
	const clss = useSelector((state) => selectClassById(state, classId));
    const navigate = useNavigate();

    const errRef = useRef(null);

    const [updateClass, {
        isSuccess,
        isLoading,
        isError,
        error
    }] = useUpdateClassMutation();

    const [name, setName] = useState('')
    const [validName, setValidName] = useState(false)
    const [nameFocus, setNameFocus] = useState(false)

    const [teacher, setTeacher] = useState('')
    const [validTeacher, setValidTeacher] = useState(false)
    const [teacherFocus, setTeacherFocus] = useState(false)

    const [timing, setTiming] = useState('')
    const [validTiming, setValidTiming] = useState(false)
    const [timingFocus, setTimingFocus] = useState(false)

    const submitRoute = '/portal/classes-list';
    const rerouteText = 'Return to Classes List';

    useEffect(() => {
        setValidName(NAME_REGEX.test(name))
    }, [name]);

    useEffect(() => {
        const timingRegex = /[A-Z]{3}[0-9]{4}-[0-9]{4},.*/i;
        setValidTiming(timingRegex.test(timing));
    }, [timing]);

    useEffect(() => {
        setValidTeacher(teacher.length > 0);
    }, [teacher]);

    const onNameChanged = e => setName(e.target.value);
    const onTeacherChanged = e => setTeacher(e.target.value);
    const onTimingChanged = e => setTiming(e.target.value);

    const onSaveUserClicked = async (e) => {
        e.preventDefault();

        let level = null;
        let clsslevel = name.substr(-3, 3);
        if (clsslevel === "BEG") level = "Beginner";
        else if (clsslevel === "NEW") level = "Newbie";
        else if (clsslevel === "EXP") level = "Expert Prac";
        else if (clsslevel === "MAP") level = "Master Prac";
        else if (clsslevel === "MED") level = "Medium";
        else if (clsslevel === "AD1") level = "Advanced 1";
        else if (clsslevel === "AD2") level = "Advanced 2";
        else if (clsslevel === "AD3") level = "Advanced 3";
        else if (clsslevel === "EX1") level = "Expert 1";
        else if (clsslevel === "EX2") level = "Expert 2";
        else if (clsslevel === "EX3") level = "Expert 3";
        else if (clsslevel === "EX4") level = "Expert 4";
        else if (clsslevel === "MA1") level = "Master 1";
        else if (clsslevel === "MA2") level = "Master 2";
        else if (clsslevel === "MA3") level = "Master 3";
        else if (clsslevel === "MA4") level = "Master 4";
        else if (clsslevel === "MA5") level = "Master 5";
        else level = clsslevel;
        await updateClass({
            id: classId,
            group_identifier: name,
            level: level,
            teacher: teacher,
            timing: timing
        });
    }

    let canSave;
    canSave = [validName, validTeacher, validTiming].every(Boolean) && !isLoading;

    let errmsg;
    if (isError) {
        window.scrollTo(0, 0);
        if (error.status === 409) {
            errmsg = <>Class name is already taken. Please choose another.</>
        } else {
            errmsg = <>An error occurred. Please try again later.</>
        }
    }

    if (isSuccess) {
        window.scrollTo(0, 0);
        return (
            <div className="register">
                <section>
                    <h2>Success!</h2>

                    <button class = "login-button" onClick={() => navigate(submitRoute)}> {rerouteText} </button>
                </section>
            </div>
        )
    }

    const content = (
        <>
        <div class = "whitespace"></div>
        <section class = "register">
            <p ref={errRef} className={isError ? "errmsg" : "offscreen"} aria-live="assertive">{errmsg}</p>
            <form onSubmit={e => e.preventDefault()} class = "login-container">
                <h1 class = "register-title">Edit Class: </h1>

                <label htmlFor="group-identifier">
                    Group Identifier<br></br>Current: {clss && clss.group_identifier}
                </label>
                <input
                    type="text"
                    id="group-identifier"
                    name="group-identifier"
                    value={name}
                    onChange={onNameChanged}
                    onFocus={() => setNameFocus(true)}
                    onBlur={() => setNameFocus(false)}
                    className={nameFocus ? (validName ? "valid" : "invalid") : ""}
                    aria-invalid={!validName}
                    aria-describedby="name"
                    required
                />
                <p id="uidnote" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
                    Group Identifier should be in the form 233-MIK-MON-AD3.
                </p>

                <label htmlFor="teacher">
                        Teacher (First name only)<br></br>Current: {clss && clss.teacher}
                    </label>
                    <input
                        type="text"
                        id="teacher"
                        onChange={onTeacherChanged}
                        value={teacher}
                        required
                        aria-invalid={validTeacher ? "false" : "true"}
                        aria-describedby="teachernote"
                        onFocus={() => setTeacherFocus(true)}
                        onBlur={() => setTeacherFocus(false)}
                    />
                    <p id="teachernote" className={teacherFocus && !validTeacher ? "instructions" : "offscreen"}>
                        First name only.
                    </p>

                <label htmlFor="timing">
                    Timing<br></br>Current: {clss && clss.timing}
                </label>
                <input
                    type="text"
                    id="timing"
                    name="timing"
                    value={timing}
                    onChange={onTimingChanged}
                    onFocus={() => setTimingFocus(true)}
                    onBlur={() => setTimingFocus(false)}
                    className={timingFocus ? (validTiming ? "valid" : "invalid") : ""}
                    aria-invalid={!validTiming}
                    aria-describedby="timingnote"
                    required
                />
                <p id="timingnote" className={timingFocus && !validTiming ? "instructions" : "offscreen"}>
                    Timing should be in the form Sat1815-1945, 9/9, 9/16, 9/23, 9/30, 10/14, 10/21, 10/28, 11/4, 11/11, 11/18, 11/25, 12/2, 12/9, 12/16, 12/23, 1/13
                </p>

                <button onClick={onSaveUserClicked} disabled={!canSave}>Save Changes</button>
            </form>
        </section>
        <div class = "whitespace"></div>
        </>
    )

    return <div className="edit-user">{content}</div>
}
export default EditClass