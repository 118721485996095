import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDeleteStudentMutation, selectStudentById, useUpdateStudentMutation, useUpdateStudentClassesMutation } from './studentsApiSlice'
import { selectUserById, useUpdateUserChildrenMutation } from '../users/usersApiSlice'
import { selectClassById, useUpdateClassStudentsMutation } from '../classes/classesApiSlice'

const Student = ({ userId, searchQuery }) => {

    const [tempPwd, setTempPwd] = useState("");
    const user = useSelector((state) => selectStudentById(state, userId));
    const parent = useSelector((state) => selectUserById(state, user.parent_id));
    const enrolled_classes = useSelector((state) => {
        return user.enrolled_classes.map((classId) => selectClassById(state, classId));
    });
    let enrolled_classes_array = [];
    if (enrolled_classes.length !== 0 && !enrolled_classes.includes(undefined)) {
        enrolled_classes_array = enrolled_classes?.map((clss) => clss && clss.group_identifier);
    }
    let enrolled_classes_string = [];
    for (let i=0; i<enrolled_classes_array.length; i++) {
        enrolled_classes_string.push(<p class = "thinner-text">{enrolled_classes_array[i]}</p>);
        //if (i !== clss.enrolled_students.length-1) enrolled_students.push(<br></br>);
    }

    const navigate = useNavigate();
    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateStudentMutation();

    const [updateChild, {
        isLoading: hi,
        isSuccess: hi2,
        isError: hi3,
        error: hi4
      }] = useUpdateUserChildrenMutation();

    const [deleteUser, {
        isSuccess: isDeleteSuccess,
        isError: isDeleteError,
        error: deleteError,
    }] = useDeleteStudentMutation();

    const [updateStudentClasses, {
        isLoading: studentLoading,
        isSuccess: studentSuccess,
        isError: studentErrorBool,
        error: studentError,
      }] = useUpdateStudentClassesMutation();
      
    const [updateClassStudents, {
        isSuccess: updateClassStudentsSuccess,
        isError: updateClassStudentsErrorBool,
        error: updateClassStudentsError,
    }] = useUpdateClassStudentsMutation();

    useEffect(() => {
        if (isSuccess) {
            alert(`Password reset for ${user.username}! Their new password is ${tempPwd}. Please note this down somewhere as it will not be shown again, and notify the individual to log in with this password and change it as soon as possible.`);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            alert(`Error resetting password: ${error.error}!`);
        }
    }, [isError]);

    const handlePwdReset = async (e) => {
        e.preventDefault();

        if (window.confirm(`Are you sure you want to reset ${user.username}'s password? This is an irreversible action.`)) {
            const pwd = Math.random().toString(36).slice(-8);
            setTempPwd(pwd);
            await updateUser({
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                changed_classes_count: user.changed_classes_count,
                enrolled_classes: user.enrolled_classes,
            });
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        const confirmDelete = window.confirm("Are you sure you want to delete? This action cannot be undone.");

        if (confirmDelete) {
            for (let i=0; i<user.enrolled_classes.length; i++) {
                await updateClassStudents({
                    class_id: user.enrolled_classes[i],
                    student_id: user.id,
                    action: "remove"
                });
                await updateClassStudents({
                    student_id: user.id,
                    class_id: user.enrolled_classes[i],
                    action: "remove"
                })
            }
            await deleteUser({ id: user.id });
            await updateChild({
                user_id: parent.id,
                child_id: user.id,
                action: "remove"
              });
            
            navigate('/portal/students-list');
        }
    }

    if (user) {

 
        if (searchQuery !== "") {
            if (!parent.first_name.toLowerCase().includes(searchQuery.toLowerCase())
                && !user.first_name.toLowerCase().includes(searchQuery.toLowerCase())
                && !parent.last_name.toLowerCase().includes(searchQuery.toLowerCase())
                && !user.last_name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return null;
            }
        }

        const handleEdit = () => navigate(`/portal/students-list/${userId}`);
        const handleView = () => navigate(`/portal/students-list/view/${userId}`);
        const userRolesString = user.role;

        return (
            <tr className={`row ${user ? (userId % 2 === 0 ? 'even' : '') : ''}`}>
                <td className="cell">{user ? user.first_name : ''}</td>
                <td className="cell">{user ? user.last_name : ''}</td>
                <td className="cell">{parent ? parent.first_name : ''}</td>
                <td className="cell">{parent ? parent.last_name : ''}</td>
                <td className="cell_smaller"><br></br>{enrolled_classes_string}<br></br></td>
                <td className="cell">{user.email}</td>
                <td className="cell">{user.phone}</td>
                <td className="cell">{user.changed_classes_count}</td>
                <td className="cell">
                    <button className="button" onClick={handleEdit}>Edit</button>
                </td>
                <td className="cell">
                    <button className="button" onClick={handleDelete}>Delete</button>
                </td>
                <td className="cell">
                    <button className="button" onClick={handleView}>View</button>
                </td>
                </tr>
        )
    } else return null;
}

export default Student