import useAuth from '../../hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useGetClassesQuery} from './classesApiSlice' // Imports redux function that gets the classes. Same format for users and students.
// Look at classesApiSlice for the things. Always useXXXX, where XXX is the function with first letter capital.
import SwapClassesTableRow from './SwapClassesTableRow.js'

const SwapClasses = () => {
  const { id, username, role } = useAuth();

 // undefined MUST be there or stuff breaks!
    // Variable classes has all of the classes. Put the data into the classes variable.
    const { data: classes, isLoading, isSuccess, isError, error } = useGetClassesQuery(undefined, {
      pollingInterval: 60000, // How often it refreshes in milliseconds (every 60 seconds)
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true // Don't worry about these two
  })
  const [searchQuery, setSearchQuery] = useState("");
  let content;
  const navigate = useNavigate();

  if (isLoading) content = <p>Loading...</p>;
  if (isError) content = <p>{error.error}</p>;
  if (isSuccess) {
      const { ids } = classes;
      if (ids.length>0) {
          let idsToShow = ids;
          const tableContent = idsToShow.map((classId) => <SwapClassesTableRow key={classId} classId={classId} searchQuery={searchQuery} userId = {id}/>);
          {/* map is like a for each loop, mapping through each class_id */}
      content = (
          <div class = "table-page">
              <br />
              <div class="right-align">
                  <h1>Swap Classes</h1>
              </div>
              <p>Note: You may only swap classes twice per term.</p>

              {/*<Link to="/portal/contests/new"><button>Create New Class</button></Link>*/}

              <input
                  type="text"
                  placeholder="Search Classes..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
              />
              <br/><br/>
              
              <table>
                  <thead>
                      <tr>
                          <th>Level</th>
                          <th>Timing</th>
                          <th>Teacher</th>
                          <th>Number of Students</th>
                          <th>Swap Class</th>
                      </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
              </table>
          </div>
      );
      }
  }

  return content;
}

export default SwapClasses