import useAuth from '../../hooks/useAuth'
import { Link } from 'react-router-dom'

const PortalHome = () => {
  const { username, role, isAdmin, isParent, isTeacher} = useAuth();

  return (
    <div>
        <br></br>
        <h1 class = "tab">Welcome, {`${username}`}</h1>
        <br></br>
        {isParent && (
          <div class="home-squares">
            <Link to="/portal/view-classes" class="square square1">
              <div class="content">
                <h2 class = "home-squares-title">View Classes</h2>
                <p class = "home-squares-subtitle">View your child's attendance record and registered classes.</p>
              </div>
            </Link>
            <Link to="/portal/swap-classes" class="square square2">
              <div class="content">
                <h2 class = "home-squares-title">Swap Classes</h2>
                <p class = "home-squares-subtitle">Change your child's class to another class in the same week.</p>
              </div>
            </Link>
            <Link to="/portal/assessment" class="square square3">
              <div class="content">
                <h2 class = "home-squares-title">Assessment</h2>
                <p class = "home-squares-subtitle">View your child's level assessment for next term.</p>
              </div>
            </Link>
          </div>
        )}

        {isAdmin && (
          <div class="home-squares">
            <Link to="/portal/classes-list" class="square square1">
              <div class="content">
                <h2 class = "home-squares-title">Edit Classes</h2>
                <p class = "home-squares-subtitle">View all available classes and change information.</p>
              </div>
            </Link>
            <Link to="/portal/users-list" class="square square2">
              <div class="content">
                <h2 class = "home-squares-title">Edit Users</h2>
                <p class = "home-squares-subtitle">Change parent, admin, or teacher information.</p>
              </div>
            </Link>
            <Link to="/portal/students-list" class="square square3">
              <div class="content">
                <h2 class = "home-squares-title">Edit Students</h2>
                <p class = "home-squares-subtitle">Change student information and connect with parent.</p>
              </div>
            </Link>
          </div>
        )}
        {isTeacher && (
          <div class="home-squares">
            <Link to="/portal/attendance" class="square square3">
              <div class="content">
                <h2 class = "home-squares-title">Attendance</h2>
                <p class = "home-squares-subtitle">Fill in attendance records for students.</p>
              </div>
            </Link>
          </div>
        )}
        <br></br>
        <br></br>
        <br></br>
    </div>
  )
}

export default PortalHome