import { useState, useEffect, useRef } from 'react';
import { useUpdateUserMutation, useDeleteUserMutation, useGetUsersQuery } from './usersApiSlice';
import { useDeleteStudentMutation, useGetStudentsQuery } from '../students/studentsApiSlice';
import { useUpdateClassStudentsMutation, useGetClassesQuery } from '../classes/classesApiSlice';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const EditUserForm = ({ user, editingAll }) => {
    const { isAdmin } = useAuth();

    const errRef = useRef(null);
    const { data: users} = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });

    const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch} = useGetStudentsQuery(undefined, {
        pollingInterval: 100,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });

    const { data: classes} = useGetClassesQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useUpdateUserMutation();

    const [deleteUser, {
        isSuccess: isDeleteSuccess,
        isError: isDeleteError,
        error: deleteError,
    }] = useDeleteUserMutation();

    const [deleteChild, {
        isSuccess: deleteChildSuccess,
        isError: deleteChildError,
        error: childError
    }] = useDeleteStudentMutation();

    const [updateClass, {
        isSuccess: updateChildSuccess,
        isError: updateChildError,
        error: classError
    }] = useUpdateClassStudentsMutation();
    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [validUsername, setValidUsername] = useState(false)
    const [usernameFocus, setUsernameFocus] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [validFirstname, setValidFirstname] = useState(false)
    const [firstnameFocus, setFirstnameFocus] = useState(false)

    const [lastname, setLastname] = useState('')
    const [validLastname, setValidLastname] = useState(false)
    const [lastnameFocus, setLastnameFocus] = useState(false)

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [emailFocus, setEmailFocus] = useState(false)

    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [phoneFocus, setPhoneFocus] = useState(false)

    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [passwordFocus, setPasswordFocus] = useState(false)

    const [matchPassword, setMatchPassword] = useState('')
    const [validMatchPassword, setValidMatchPassword] = useState(false)
    const [matchPasswordFocus, setMatchPasswordFocus] = useState(false)

    const [roles, setRoles] = useState(user.role);

    const submitRoute = editingAll ? '/portal/users-list' : '/portal';
    const rerouteText = editingAll ? 'Return to Users List' : 'Return to Portal Home';

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        setValidFirstname(firstname.length > 0)
    }, [firstname])

    useEffect(() => {
        setValidLastname(lastname.length > 0)
    }, [lastname])

    useEffect(() => {
        const phoneRegex = /[0-9]{3}[0-9]{3}[0-9]{4}/i;
        setValidPhone(phoneRegex.test(phone));
    }, [phone]);

    useEffect(() => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        setValidEmail(emailRegex.test(email));
    }, [email]);

    const onUsernameChanged = e => setUsername(e.target.value);
    const onFirstnameChanged = e => setFirstname(e.target.value);
    const onLastnameChanged = e => setLastname(e.target.value);
    const onEmailChanged = e => setEmail(e.target.value);
    const onPhoneChanged = e => setPhone(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value);
    const onRolesChanged = (e) => setRoles(e.target.value);

    const onSaveUserClicked = async (e) => {
        // Don't need to update children because children will only be added if email matches, so you actually can't.
        if (password) {
            await updateUser({
                id: user.id,
                username: username,
                password: password,
                role: roles,
                first_name: firstname,
                last_name: lastname,
                email: email,
                phone: phone
            });
        } else {
            await updateUser({
                id: user.id,
                username: username,
                role: roles,
                first_name: firstname,
                last_name: lastname,
                email: email,
                phone: phone
            });
        }
    }

    const onDeleteUserClicked = async (e) => {
        e.preventDefault();

        const confirmDelete = window.confirm("Are you sure you want to delete? This action cannot be undone.");

        if (confirmDelete) {
            // When a user is deleted (EditUserForm), their children also need to be deleted. Their children's classes also need to be deleted.
            const { ids: students, entities: stud_ent } = studs;
            const { ids: userIds, entities: user_ent} = users;
            const { ids: classIds, entities: class_ent} = classes;
            for (const childId of user_ent[user.id].children_ids) {
                // Look for classes they are in and delete those too.
                let toDeleteClassIds = [];
                for (const classId of classIds) {
                    if ((class_ent[classId].enrolled_students).includes(childId)) {
                        toDeleteClassIds.push(classId);
                    }
                }
                for (const deleteClassId of toDeleteClassIds) {
                    await updateClass({
                        class_id: deleteClassId,
                        student_id: childId,
                        action: "remove"
                    });
                }
                await deleteChild({
                    id: childId
                });
            }
            await deleteUser({ id: user.id });
            navigate(submitRoute);
        }
    }

    let canSave;
    if (password) canSave = [validUsername, validFirstname, validLastname, validPhone, validEmail, validPassword].every(Boolean) && !isLoading;
    else canSave = [validUsername, validFirstname, validLastname, validPhone, validEmail].every(Boolean) && !isLoading;

    let errmsg;
    if (isError) {
        window.scrollTo(0, 0);
        if (error.status === 409) {
            errmsg = <>Username is already taken. Please choose another.</>
        } else {
            errmsg = <>An error occurred. Please try again later.</>
        }
    } else if (isDeleteError) {
        window.scrollTo(0, 0);
        errmsg = <>An error occurred. Please try again later.</>
    }

    if (isSuccess) {
        window.scrollTo(0, 0);
        return (
            <div className="register">
                <section>
                    <h2>Success!</h2>
                    <p>User <strong><em>{username}</em></strong> has been successfully updated.</p>

                    <button class = "login-button" onClick={() => navigate(submitRoute)}> {rerouteText} </button>
                </section>
            </div>
        )
    }

    const content = (
        <>
        <div class = "whitespace"></div>
        <section class = "register">
            <p ref={errRef} className={isError ? "errmsg" : "offscreen"} aria-live="assertive">{errmsg}</p>
            <form onSubmit={e => e.preventDefault()} class = "login-container">
                <h1 class = "register-title">Edit User Data</h1>
                <p>All fields except for the password are mandatory.<br></br>Please copy your current data if you wish to keep it.</p>
                <label htmlFor="username">
                    Username:<br></br>Current: {user && user.username}
                </label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={onUsernameChanged}
                    onFocus={() => setUsernameFocus(true)}
                    onBlur={() => setUsernameFocus(false)}
                    className={usernameFocus ? (validUsername ? "valid" : "invalid") : ""}
                    aria-invalid={!validUsername}
                    aria-describedby="username-err"
                    required
                />
                <p id="uidnote" className={usernameFocus && username && !validUsername ? "instructions" : "offscreen"}>
                    4 to 24 characters.<br />
                    Must begin with a letter.<br />
                    Letters, numbers, underscores, hyphens allowed.
                </p>

                <label htmlFor="firstname">
                    First Name:<br></br>Current: {user && user.first_name}
                </label>
                <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={firstname}
                    onChange={onFirstnameChanged}
                    onFocus={() => setFirstnameFocus(true)}
                    onBlur={() => setFirstnameFocus(false)}
                    className={firstnameFocus ? (validFirstname ? "valid" : "invalid") : ""}
                    aria-invalid={!validFirstname}
                    aria-describedby="firstname-err"
                    required
                />
                <p id="firstnote" className={firstnameFocus && !validFirstname ? "instructions" : "offscreen"}>
                    You must enter a first name.
                </p>

                <label htmlFor="lastname">
                    Last Name:<br></br>Current: {user && user.last_name}
                </label>
                <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    value={lastname}
                    onChange={onLastnameChanged}
                    onFocus={() => setLastnameFocus(true)}
                    onBlur={() => setLastnameFocus(false)}
                    className={lastnameFocus ? (validLastname ? "valid" : "invalid") : ""}
                    aria-invalid={!validLastname}
                    aria-describedby="lastname-err"
                    required
                />
                <p id="lastnote" className={lastnameFocus && !validLastname ? "instructions" : "offscreen"}>
                    You must enter a last name.
                </p>

                <label htmlFor="phone">
                        Phone Number:<br></br>Current: {user && user.phone}
                    </label>
                    <input
                        type="tel"
                        id="phone"
                        onChange={onPhoneChanged}
                        value={phone}
                        required
                        aria-invalid={validPhone ? "false" : "true"}
                        aria-describedby="phonenote"
                        onFocus={() => setPhoneFocus(true)}
                        onBlur={() => setPhoneFocus(false)}
                    />
                    <p id="phonenote" className={phoneFocus && !validPhone ? "instructions" : "offscreen"}>
                        Must be a valid phone number in the form XXXXXXXXXX.
                    </p>

                <label htmlFor="email">
                    Email:<br></br>Current: {user && user.email}
                </label>
                <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={onEmailChanged}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    className={emailFocus ? (validEmail ? "valid" : "invalid") : ""}
                    aria-invalid={!validEmail}
                    aria-describedby="emailnote"
                    required
                />
                <p id="emailnote" className={emailFocus && !validEmail ? "instructions" : "offscreen"}>
                    You must enter a valid email address.
                </p>

                {
                    !editingAll &&
                    <>
                        <label htmlFor="password">
                            Password (Leave Empty to Keep Current):
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={onPasswordChanged}
                            onFocus={() => setPasswordFocus(true)}
                            onBlur={() => setPasswordFocus(false)}
                            className={passwordFocus ? (validPassword ? "valid" : "invalid") : ""}
                            aria-invalid={!validPassword}
                            aria-describedby="passwordnote"
                        />
                        <p id="passwordnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                            8 to 24 characters.<br />
                            Must contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                        </p>
                    </>
                }

                {isAdmin && editingAll &&
                    <>
                        <label htmlFor="roles">
                            Role:
                        </label>
                        <select
                            id="roles"
                            onChange={onRolesChanged}
                            value={roles}
                            required
                            className="select-roles"
                        >
                            <option value="Parent">Parent</option>
                            <option value="Teacher">Teacher</option>
                            <option value="Admin">Admin</option>
                        </select>
                    </>
                }
                <br></br>

                <button onClick={onSaveUserClicked} disabled={!canSave}>Save Changes</button>
            </form>

            {
                (isAdmin && editingAll) &&
                <button onClick={onDeleteUserClicked} className="delete-button">
                    Delete User
                </button>
            }
        </section>
        <div class = "whitespace"></div>
        </>
    )

    return <div className="edit-user">{content}</div>
}

export default EditUserForm