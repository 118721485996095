import { Link } from 'react-router-dom';

const Public = () => {
  return (
    <div>
      <header>
        <Link to="/"><img id="logo-link" src="./images/logo.png" alt="CCYC Logo" /></Link>
        <h1 id="title">Parent and Teacher Portal</h1>
        <div id="button-container">
          <Link to="/login">
            <button class="login-button">Login</button>
          </Link>
        </div>
      </header>

      <section class="hero">
        <h1>Welcome to the CCYC Portal</h1>
        <p>View and change student information and classes.</p>
      </section>
      <section class="courses">
        <h2>Level Assessment Tool</h2>
        <div class="course">
          <p>Click the button below to begin the level assessment tool. Please complete the level assessment tool on a tablet or a computer.<br></br><br></br>The quiz will take around 10 to 15 minutes to complete.</p>
        </div>
        <Link to="/levelassess">
          <a class="btn">Start Now</a>
        </Link>
      </section>
      {/*
  <section class="hero">
    <Link to="/login">
      <a class="login-button hi">Login Here</a>
    </Link>
    <Link to="/levelassess">
      <a class="btn hi">Level Assessment Tool</a>
    </Link>
  </section>
  */}

      <footer>
        <p>&copy; 2023 CCYC. All rights reserved.</p>
      </footer>

    </div>
  )
}

export default Public