import React, { useState, useEffect }  from 'react';

const LA3B = ({done, setDone, level, setLevel, onPrevious, onSubmit }) => {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [answer5, setAnswer5] = useState('');
  const [answer6, setAnswer6] = useState('');
  const [answer7, setAnswer7] = useState('');

  const [score, setScore] = useState(0);

  const handleSelect1 = (value) => {
    setAnswer1(value);
  };
  const handleSelect2 = (value) => {
    setAnswer2(value);
  };
  const handleSelect3 = (value) => {
    setAnswer3(value);
  };
  const handleSelect4 = (value) => {
    setAnswer4(value);
  };
  const handleSelect5 = (value) => {
    setAnswer5(value);
  };
  const handleSelect6 = (value) => {
    setAnswer6(value);
  };
  const handleSelect7 = (value) => {
    setAnswer7(value);
  };
  useEffect(() => {
  let newScore = 0;
  if (answer1 === 'b') newScore++;
  if (answer2 === 'e') newScore++;
  if (answer3 === 'a') newScore++;
  if (answer4 === 'a') newScore++;
  if (answer5 === 'a') newScore++;
  if (answer6 === 'a') newScore++;
  if (answer7 === 'a') newScore++;
  setScore(newScore);
}, [answer1, answer2, answer3, answer4, answer5, answer6, answer7]);

  const handleSubmit = () => {
    if (score >= 4) {
      if (done === 0 || done === 2) {
        setLevel(level+1);
        setDone(2);
      }
      else {
        setLevel(level+1);
        setDone(1000);
      }
    }
    else if (score >= 1 || done === 2) {
      setDone(1000);
    }
    else {
      if (done === 2) {
        setLevel(level-1);
        setDone(1000);
      }
      else {
        setDone(1);
        setLevel(level-1);
      }
    }
    window.scrollTo({ top: 500, left: 0 });
  };

  useEffect(() => {
  onSubmit();
}, []);

  return (
    <div>
      <h1>Level Assessment</h1>
      <div>
        <label>To which of the following squares can Black move their bishop currently on c8 in order to win significant material?</label>
        <div className="options">
        <div
          className={`option ${answer1 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect1('a')}
        >
          <span>h3</span>
        </div>
        <div
          className={`option ${answer1 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect1('b')}
        >
          <span>g4</span>
        </div>
        <div
          className={`option ${answer1 === 'c' ? 'selected' : ''}`}
          onClick={() => handleSelect1('c')}
        >
          <span>f5</span>
        </div>
        <div
          className={`option ${answer1 === 'd' ? 'selected' : ''}`}
          onClick={() => handleSelect1('d')}
        >
          <span>e6</span>
        </div>
        <div
          className={`option ${answer1 === 'e' ? 'selected' : ''}`}
          onClick={() => handleSelect1('e')}
        >
          <span>d7</span>
        </div>
      </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/fNdxrEer" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>To which of the following squares can Black move their knight currently on f4 in order to win significant material?</label>
        <div className="options">
        <div
          className={`option ${answer2 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect2('a')}
        >
          <span>g6</span>
        </div>
        <div
          className={`option ${answer2 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect2('b')}
        >
          <span>d5</span>
        </div>
        <div
          className={`option ${answer2 === 'c' ? 'selected' : ''}`}
          onClick={() => handleSelect2('c')}
        >
          <span>g2</span>
        </div>
        <div
          className={`option ${answer2 === 'd' ? 'selected' : ''}`}
          onClick={() => handleSelect2('d')}
        >
          <span>h3</span>
        </div>
        <div
          className={`option ${answer2 === 'e' ? 'selected' : ''}`}
          onClick={() => handleSelect2('e')}
        >
          <span>e2</span>
        </div>
      </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/34tYvbPq" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>White has just moved their pawn from e2 to e4. Your pawn on f4 captures their pawn using the en passant rule.<br></br>Where would your pawn be after this capture?</label>
        <div className="options">
        <div
          className={`option ${answer3 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect3('a')}
        >
          <span>e3</span>
        </div>
        <div
          className={`option ${answer3 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect3('b')}
        >
          <span>e4</span>
        </div>
        <div
          className={`option ${answer3 === 'c' ? 'selected' : ''}`}
          onClick={() => handleSelect3('c')}
        >
          <span>e2</span>
        </div>
        <div
          className={`option ${answer3 === 'd' ? 'selected' : ''}`}
          onClick={() => handleSelect3('d')}
        >
          <span>f4</span>
        </div>
        <div
          className={`option ${answer3 === 'e' ? 'selected' : ''}`}
          onClick={() => handleSelect3('e')}
        >
          <span>I don't know what the en passant rule is.</span>
        </div>
      </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/Qcg1D7kU" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>Do you know how to checkmate your opponent if you have a queen and a king, and your opponent only has a king?</label>
        <div className="options">
        <div
          className={`option ${answer4 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect4('a')}
        >
          <span>Yes</span>
        </div>
        <div
          className={`option ${answer4 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect4('b')}
        >
          <span>No</span>
        </div>
      </div>
      <div>
        <label>Do you know the basics of the Giocco Piano and Fried Liver Attack?</label>
        <div className="options">
        <div
          className={`option ${answer5 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect5('a')}
        >
          <span>Yes</span>
        </div>
        <div
          className={`option ${answer5 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect5('b')}
        >
          <span>No</span>
        </div>
      </div>
      </div>
      <div>
        <label>Do you know what a passed pawn is?</label>
        <div className="options">
        <div
          className={`option ${answer6 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect6('a')}
        >
          <span>Yes</span>
        </div>
        <div
          className={`option ${answer6 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect6('b')}
        >
          <span>No</span>
        </div>
      </div>
      </div>
      <div>
        <label>Do you know what castling is?</label>
        <div className="options">
        <div
          className={`option ${answer7 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect7('a')}
        >
          <span>Yes</span>
        </div>
        <div
          className={`option ${answer7 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect7('b')}
        >
          <span>No</span>
        </div>
      </div>
      </div>


      </div>

      <button class = "la-button" onClick={onPrevious}>Previous</button>
      <button class = "la-button" onClick={handleSubmit}>Next</button>
    </div>
  );
};

export default LA3B;