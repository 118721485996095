import { useRef, useState, useEffect } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useAddNewStudentMutation, useGetStudentsQuery, useUpdateStudentClassesMutation } from './studentsApiSlice';
import { useGetUsersQuery, useUpdateUserChildrenMutation } from '../users/usersApiSlice';
import { useGetClassesQuery, useAddNewClassMutation, useUpdateClassStudentsMutation } from '../classes/classesApiSlice';
const enrolledClassesRegex = /[0-9]{3}-[A-Z]{3}-[A-Z]{3}-.{3}.*/i;

const AddStudent = () => {

    const errRef = useRef(null);

    const { data: users} = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
    
      const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch} = useGetStudentsQuery(undefined, {
        pollingInterval: 100,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
        
      const { data: classes} = useGetClassesQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
    
      const [addStudent, {
        isLoading,
        isSuccess,
        isError,
        error,
        reset
    }] = useAddNewStudentMutation();
    
    const [addClass, {
      isLoading: classAddLoading,
      isSuccess: classAddSuccess,
      isError: classAddErrorBool,
      error: classAddError,
    }] = useAddNewClassMutation();
    
    const [updateStudentClasses, {
      isLoading: studentLoading,
      isSuccess: studentSuccess,
      isError: studentErrorBool,
      error: studentError,
    }] = useUpdateStudentClassesMutation();
    
    const [updateClassStudents, {
      isLoading: classStudentsUpdateLoading,
      isSuccess: classStudentsUpdateSuccess,
      isError: classStudentsUpdateErrorBool,
      error: classStudentsUpdateError,
    }] = useUpdateClassStudentsMutation();
    
    const [updateChild, {
      isLoading: hi,
      isSuccess: hi2,
      isError: hi3,
      error: hi4
    }] = useUpdateUserChildrenMutation();

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('')
    const [validFirstName, setValidFirstName] = useState(false)
    const [firstNameFocus, setFirstNameFocus] = useState(false)

    const [lastName, setLastName] = useState('')
    const [validLastName, setValidLastName] = useState(false)
    const [lastNameFocus, setLastNameFocus] = useState(false)

    const [parentFirstName, setParentFirstName] = useState('')
    const [validParentFirstName, setValidParentFirstName] = useState(false)
    const [parentFirstNameFocus, setParentFirstNameFocus] = useState(false)

    const [parentLastName, setParentLastName] = useState('')
    const [validParentLastName, setValidParentLastName] = useState(false)
    const [parentLastNameFocus, setParentLastNameFocus] = useState(false)

    const [enrolledClasses, setEnrolledClasses] = useState('')
    const [validEnrolledClasses, setValidEnrolledClasses] = useState(false)
    const [enrolledClassesFocus, setEnrolledClassesFocus] = useState(false)

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [emailFocus, setEmailFocus] = useState(false)

    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [phoneFocus, setPhoneFocus] = useState(false)

    const [ecArray, setEcArray] = useState([]);
    const [studAdded, setStudAdded] = useState(false);

    const submitRoute = '/portal/students-list';
    const rerouteText = 'Return to Students List';

    useEffect(() => {
        setValidFirstName(firstName.length > 0)
    }, [firstName]);

    useEffect(() => {
        setValidLastName(lastName.length > 0)
    }, [lastName]);

    useEffect(() => {
        setValidParentFirstName(parentFirstName.length > 0)
    }, [parentFirstName]);

    useEffect(() => {
        setValidParentLastName(parentLastName.length > 0)
    }, [parentLastName]);

    useEffect(() => {
        const { ids: classIds, entities: class_entities} = classes;
        let enrolledClassExists = true;
        let student_enroll = enrolledClasses;
        let enrolled_classesx = [];
        while (student_enroll.includes("/")) {
            enrolled_classesx.push(student_enroll.slice(0, student_enroll.indexOf("/")));
            student_enroll = student_enroll.slice(student_enroll.indexOf("/") + 1);
        }
        enrolled_classesx.push(student_enroll);
        let enrollBool = [];
        for (const element of enrolled_classesx) {
            let boolEC = false;
            for (const id of classIds) {
                if (class_entities[id].group_identifier === element) {
                    boolEC = true;
                }
            }
            enrollBool.push(boolEC);
        }
        for (const bool of enrollBool) {
            if (!bool) {
                enrolledClassExists = false;
            }
        }
        setValidEnrolledClasses(enrolledClassesRegex.test(enrolledClasses) && enrolledClassExists);
    }, [enrolledClasses]);

    useEffect(() => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        setValidEmail(emailRegex.test(email));
    }, [email]);
  
      useEffect(() => {
          const phoneRegex = /[0-9]{3}[0-9]{3}[0-9]{4}/i;
          setValidPhone(phoneRegex.test(phone));
      }, [phone]);
  
    useEffect(() => {
        const thing = async () => {
            const { ids: newStudents, entities: s } = studs;
        
                const stud_ids = newStudents.map(id => {
                    if (s[id].first_name === firstName && s[id].last_name === lastName && s[id].email === email) {
                        return id;
                    }
                    return 0;
                })

                let studentId = 0;
                for (const element of stud_ids) {
                    if (element !== 0) studentId = element;
                }

                // Check that class wasn't already added
                if (studentId && s[studentId].enrolled_classes.length === 0) {
                    await updateChild({
                        user_id: s[studentId].parent_id,
                        child_id: studentId,
                        action: "add"
                    });

                    for (let l=0; l<ecArray.length; l++) {
                        for (const element of ecArray) {
                            const response = await updateStudentClasses({
                                student_id: studentId,
                                class_id: element,
                                action: "add"
                            });
                            const hi = await updateClassStudents({
                                class_id: element,
                                student_id: studentId,
                                action: "add"
                            });
                        }       
                    }
                }
        }

        thing();
    }, [studs]);

    const onFirstNameChanged = e => setFirstName(e.target.value);
    const onLastNameChanged = e => setLastName(e.target.value);
    const onParentFirstNameChanged = e => setParentFirstName(e.target.value);
    const onParentLastNameChanged = e => setParentLastName(e.target.value);
    const onEnrolledClassesChanged = e => setEnrolledClasses(e.target.value);
    const onEmailChanged = e => setEmail(e.target.value);
    const onPhoneChanged = e => setPhone(e.target.value);

    const onSaveUserClicked = async (e) => {
        e.preventDefault();
        
        let student_enroll = enrolledClasses;
        let enrolled_classesx = [];
        while (student_enroll.includes("/")) {
            enrolled_classesx.push(student_enroll.slice(0, student_enroll.indexOf("/")));
            student_enroll = student_enroll.slice(student_enroll.indexOf("/") + 1);
        }
        enrolled_classesx.push(student_enroll);
        let enrolled_classes = [];
        const { ids: classIds, entities: class_entities} = classes;
        const { ids, entities } = users;
        const { ids: students, entities: stud_ent } = studs;
        // Turn the enrolled_classes into their IDs
        const class_ids = classIds?.map(classId => {
            for (let k=0; k < enrolled_classesx.length; k++) {
            if (class_entities[classId].group_identifier === enrolled_classesx[k]) {
                return 1;
            }
            }
            return 0;
        });

        for (let k=0; k<class_ids.length; k++) {
            if (class_ids[k] === 1) {
            enrolled_classes.push(classIds[k]);
            }
        }

        setEcArray(enrolled_classes);

        // Find parent_id using map
        const parent_ids = ids?.map(userId => {
            if (email === entities[userId].email) {
            const student_match = students?.map(studentId => {
                if (email === stud_ent[studentId].email && firstName === stud_ent[studentId].first_name && lastName === stud_ent[studentId].last_name) {
                return "1";
                } else {
                return "0";
                }
            });
            for (let k = 0; k < student_match.length; k++) {
                if (student_match[k] === 1) {
                return "0";
                }
            }
            return userId;
            }
            return "0";
        });

        let boolasd = false;
        let parentId = 0;
        for (let j = 0; j < parent_ids.length; j++) {
            if (parent_ids[j] !== "0") {
            parentId = parent_ids[j];
            boolasd = true;
            }
        }
        if (!boolasd) {
            parentId = 0;
        }
        // (TODO) Get the ID's of the enrolled classes (but need to delete users first)
        // (TODO) Add the student to the class as well
        if (parentId !== 0) {
            let go = true;
            for (const student of students) {
            if (stud_ent[student].first_name === firstName && stud_ent[student].last_name === lastName && stud_ent[student].email === email) {
                go = false;
            }
            }
            // let child_ids = users[parentId] && users[parentId].children_ids ? users[parentId].children_ids : [];
            // for (let l=0; l<child_ids.length; l++) {}
        
            if (go) {
                const operation = await addStudent({
                    first_name: firstName,
                    last_name: lastName,
                    parent_id: parentId,
                    email: email,
                    phone: phone
                });

                await refetch();
            }
            
        }
    }

    let canSave;
    canSave = [validFirstName, validLastName, validParentFirstName, validParentLastName, validEmail, validPhone].every(Boolean) && !isLoading;

    let errmsg;
    if (isError) {
        window.scrollTo(0, 0);
        if (error.status === 409) {
            errmsg = <>Class name is already taken. Please choose another.</>
        } else {
            errmsg = <>An error occurred. Please try again later.</>
        }
    }

    if (isSuccess) {
        window.scrollTo(0, 0);
        return (
            <div className="register">
                <div class = "whitespace"></div>
                <section>
                    <h2>Success!</h2>

                    <button class = "login-button" onClick={() => navigate(submitRoute)}> {rerouteText} </button>
                </section>
                <div class = "whitespace"></div>
            </div>
        )
    }

    const content = (
        <>
        <div class = "whitespace"></div>
        <section class = "register">
            <p ref={errRef} className={isError ? "errmsg" : "offscreen"} aria-live="assertive">{errmsg}</p>
            <form onSubmit={e => e.preventDefault()} class = "login-container">
                <h1 class = "register-title">Add Student</h1>

                <label htmlFor="first-name">
                    Student First Name
                </label>
                <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    value={firstName}
                    onChange={onFirstNameChanged}
                    onFocus={() => setFirstNameFocus(true)}
                    onBlur={() => setFirstNameFocus(false)}
                    className={firstNameFocus ? (validFirstName ? "valid" : "invalid") : ""}
                    aria-invalid={!validFirstName}
                    aria-describedby="first-name"
                    required
                />
                <p id="first-name" className={firstNameFocus && firstName && !validFirstName ? "instructions" : "offscreen"}>
                    Invalid first name.
                </p>

                <label htmlFor="last-name">
                    Student Last Name
                </label>
                <input
                    type="text"
                    id="last-name"
                    name="last-name"
                    value={lastName}
                    onChange={onLastNameChanged}
                    onFocus={() => setLastNameFocus(true)}
                    onBlur={() => setLastNameFocus(false)}
                    className={lastNameFocus ? (validLastName ? "valid" : "invalid") : ""}
                    aria-invalid={!validLastName}
                    aria-describedby="last-name"
                    required
                />
                <p id="last-name" className={lastNameFocus && lastName && !validLastName ? "instructions" : "offscreen"}>
                    Invalid last name.
                </p>

                <label htmlFor="parent-first-name">
                    Parent First Name
                </label>
                <input
                    type="text"
                    id="parent-first-name"
                    name="parent-first-name"
                    value={parentFirstName}
                    onChange={onParentFirstNameChanged}
                    onFocus={() => setParentFirstNameFocus(true)}
                    onBlur={() => setParentFirstNameFocus(false)}
                    className={parentFirstNameFocus ? (validParentFirstName ? "valid" : "invalid") : ""}
                    aria-invalid={!validParentFirstName}
                    aria-describedby="parent-first-name"
                    required
                />
                <p id="parent-first-name" className={parentFirstNameFocus && parentFirstName && !validParentFirstName ? "instructions" : "offscreen"}>
                    Invalid parent first name.
                </p>

                <label htmlFor="parent-last-name">
                    Parent Last Name
                </label>
                <input
                    type="text"
                    id="parent-last-name"
                    name="parent-last-name"
                    value={parentLastName}
                    onChange={onParentLastNameChanged}
                    onFocus={() => setParentLastNameFocus(true)}
                    onBlur={() => setParentLastNameFocus(false)}
                    className={parentLastNameFocus ? (validParentLastName ? "valid" : "invalid") : ""}
                    aria-invalid={!validParentLastName}
                    aria-describedby="parent-last-name"
                    required
                />
                <p id="parent-last-name" className={parentLastNameFocus && parentLastName && !validParentLastName ? "instructions" : "offscreen"}>
                    Invalid parent last name.
                </p>

                <label htmlFor="enrolled-classes">
                    Enrolled Classes
                </label>
                <input
                    type="text"
                    id="enrolled-classes"
                    name="enrolled-classes"
                    value={enrolledClasses}
                    onChange={onEnrolledClassesChanged}
                    onFocus={() => setEnrolledClassesFocus(true)}
                    onBlur={() => setEnrolledClassesFocus(false)}
                    className={enrolledClassesFocus ? (validEnrolledClasses ? "valid" : "invalid") : ""}
                    aria-invalid={!validEnrolledClasses}
                    aria-describedby="enrolled-classes"
                    required
                />
                <p id="enrolled-classes" className={enrolledClassesFocus && enrolledClasses && !validEnrolledClasses ? "instructions" : "offscreen"}>
                    Invalid classes or classes not found.<br></br>Provide enrolled classes separated by "/"<br></br>(Example: 233-MIK-MON-AD2/233-MIK-MON-AD3)
                </p>

                <label htmlFor="email">
                        Email<br></br>(Must match email provided when registering)
                    </label>
                    <input
                        type="text"
                        id="email"
                        onChange={onEmailChanged}
                        value={email}
                        required
                        aria-invalid={validEmail ? "false" : "true"}
                        aria-describedby="emailnote"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                    />
                    <p id="emailnote" className={emailFocus && !validEmail ? "instructions" : "offscreen"}>
                        Must be a valid email address.
                    </p>

                    <label htmlFor="phone">
                        Phone Number
                    </label>
                    <input
                        type="tel"
                        id="phone"
                        onChange={onPhoneChanged}
                        value={phone}
                        required
                        aria-invalid={validPhone ? "false" : "true"}
                        aria-describedby="emailnote"
                        onFocus={() => setPhoneFocus(true)}
                        onBlur={() => setPhoneFocus(false)}
                    />
                    <p id="phonenote" className={phoneFocus && !validPhone ? "instructions" : "offscreen"}>
                        Must be a valid phone number in the form XXXXXXXXXX.
                    </p>

                <button onClick={onSaveUserClicked} disabled={!canSave}>Save Changes</button>
            </form>       
        </section>
        <div class = "whitespace"></div>
        </>
    )

    return <div className="edit-user">{content}</div>
}
export default AddStudent