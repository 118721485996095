import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const PortalHeader = () => {
  const { isAdmin, isParent } = useAuth();

  const navigate = useNavigate();

  const [sendLogout, { isLoading, isSuccess, isError, error }] = useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate('/');
  }, [isSuccess, navigate]);

  const handleLogout = () => {
    sendLogout();
    navigate('/');
  }

  if (isLoading) return <p>Logging Out...</p>

  if (isError) return <p>Unable to Logout: {error.data?.message}</p>

  const logoutButton = (
    <button
      className="logout"
      onClick={handleLogout}
    > Logout </button>
  )

  const header = (
    <header>
          <Link to="/portal"><img id="logo-link" src="./images/logo.png" alt="CCYC Logo"/></Link>
          <h1 id = "title">Parent and Teacher Portal</h1>

            <div class="dropdown">
              <button class="dropbtn">Menu</button>
              <div class="dropdown-content">
                <Link to="/portal/edit-self">Edit Account</Link>
                {isAdmin && <Link to="/portal/import-csv">Import CSV</Link>}
                <Link onClick={handleLogout}>Logout</Link>
              </div>
            </div>
    </header>
  )

  return header;
}

export default PortalHeader