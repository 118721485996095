import { useState } from 'react';
import { useGetStudentsQuery } from './studentsApiSlice';
import { Link, useNavigate } from 'react-router-dom'
import Student from './Student';

const StudentsList = () => {
  const { data: students, isLoading, isSuccess, isError, error } = useGetStudentsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');

  let content;

  if (isLoading) content = <p>Loading...</p>;

  if (isError) content = <p>{error.error}</p>;

  if (isSuccess) {
    const handleAdd = () => navigate(`/portal/add-student/`);
    const { ids } = students;

    const tableContent = ids?.map(userId => <Student key={userId} userId={userId} searchQuery={searchQuery}/>);
    content = (
      <div class = "table-page">
        <br />
        <div class="right-align">
          <h1>Edit Students</h1> <br />
          <button class="add-class" onClick={handleAdd}>Add Student</button>
        </div>

        <input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search Students..."
        />
        <br /><br />
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Parent First Name</th>
              <th>Parent Last Name</th>
              <th>Enrolled Classes</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Changed Classes</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      </div>
    );
  }

  return content;
};

export default StudentsList;