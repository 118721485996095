import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { selectStudentById } from '../students/studentsApiSlice';
import { useGetClassesQuery } from './classesApiSlice';

function findNthDotIndex(attendance_data, n) {
    let dotCount = 0;
    for (let i = 0; i < attendance_data.length; i++) {
      if (attendance_data[i] === '.') {
        dotCount++;
        if (dotCount === n) {
          return i; // Found the i-th dot, return its index
        }
      }
    }
    return -1; // If not found, return -1
  }

  function checkIfSameWeek(targetDateStr) {
    // Parse the target date string in the "M/D" format
    const [targetMonth, targetDay] = targetDateStr.split('/').map(Number);
  
    // Create Date objects for the current date and the target date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const targetDate = new Date(currentYear, targetMonth - 1, targetDay);
  
    // Calculate the start of the current week as the current date
    const currentWeekStart = new Date(currentDate);
  
    // Calculate the end of the current week, accounting for month and year transitions
    const currentWeekEnd = new Date(currentWeekStart);

    currentWeekEnd.setDate(currentWeekStart.getDate() + 7);

    // Check if the target date falls within the same week
    return (
      (targetDate >= currentWeekStart && targetDate <= currentWeekEnd) ||
      (targetDate >= currentWeekStart && (targetDate.getMonth() !== currentMonth || targetDate.getFullYear() !== currentYear))
    );
  }

const Class = (studentId) => {
    const student = useSelector((state) => selectStudentById(state, studentId.studentId));
    const { data: classes, isLoading, isSuccess, isError, error } = useGetClassesQuery(undefined, {
        pollingInterval: 60000, // How often it refreshes in milliseconds (every 60 seconds)
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true // Don't worry about these two
    })
    const [attendanceReport, setAttendanceReport] = useState([]);
    const [makeup, setMakeup] = useState([]);
    let tableContent = [];
    let numberOfClasses = 0;
    useEffect(() => {
        if (student) {
            numberOfClasses = 0;
            let attendanceReport = [];
            if (student.enrolled_classes) {
                for (const clss of student.enrolled_classes) {
                    if (classes) {
                        const {ids: classIds, entities: classEnts} = classes;
                        
                        let makeupDates = [];
                        const classesToDisplay = classIds.map(id => {
                            for (let i=0; i<classEnts[id].attendance_data.length; i++) {
                                if (classEnts[id].attendance_data[i][0] === "M" && checkIfSameWeek(classEnts[id].attendance_data[i].substr(1, classEnts[id].attendance_data[i].indexOf(".")-1)) && classEnts[id].attendance_data[i].substr(classEnts[id].attendance_data[i].indexOf(".")+1) === studentId.studentId) {
                                    makeupDates.push(classEnts[id].attendance_data[i].substr(1, classEnts[id].attendance_data[i].indexOf(".")-1));
                                    return id;
                                }
                            }
                            return 0;
                        });

                        let counter = 0;
                        let makeup_classes = [];
                        for (const element of classesToDisplay) {
                            if (element !== 0) {
                                let level = null;
                                if (classEnts[element].level === "BEG") level = "Beginner";
                                else if (classEnts[element].level === "NEW") level = "Newbie";
                                else if (classEnts[element].level === "EXP") level = "Expert Prac";
                                else if (classEnts[element].level === "MAP") level = "Master Prac";
                                else if (classEnts[element].level === "MED") level = "Medium";
                                else if (classEnts[element].level === "AD1") level = "Advanced 1";
                                else if (classEnts[element].level === "AD2") level = "Advanced 2";
                                else if (classEnts[element].level === "AD3") level = "Advanced 3";
                                else if (classEnts[element].level === "EX1") level = "Expert 1";
                                else if (classEnts[element].level === "EX2") level = "Expert 2";
                                else if (classEnts[element].level === "EX3") level = "Expert 3";
                                else if (classEnts[element].level === "EX4") level = "Expert 4";
                                else if (classEnts[element].level === "MA1") level = "Master 1";
                                else if (classEnts[element].level === "MA2") level = "Master 2";
                                else if (classEnts[element].level === "MA3") level = "Master 3";
                                else if (classEnts[element].level === "MA4") level = "Master 4";
                                else if (classEnts[element].level === "MA5") level = "Master 5";
                                else level = classEnts[element].level;
                                makeup_classes.push(<p>{level + ", "+ classEnts[element].teacher + ", " + makeupDates[counter] + ", " + classEnts[element].timing.slice(3, 5) + ":" + classEnts[element].timing.slice(5, 10) + ":" + classEnts[element].timing.slice(10, 12)}</p>);
                                counter ++;
                            }
                        }
                        setMakeup(makeup_classes);
                        setAttendanceReport()
                    let toPrint = [];
                    toPrint.push(classEnts[clss].teacher);
                
                    // Parse Level
                    let level = null;
                    if (classEnts[clss].level === "BEG") level = <p>Beginner</p>;
                    else if (classEnts[clss].level === "NEW") level = <p>Newbie</p>;
                    else if (classEnts[clss].level === "EXP") level = <p>Expert Prac</p>;
                    else if (classEnts[clss].level === "MAP") level = <p>Master Prac</p>;
                    else if (classEnts[clss].level === "MED") level = <p>Medium</p>;
                    else if (classEnts[clss].level === "AD1") level = <p>Advanced 1</p>;
                    else if (classEnts[clss].level === "AD2") level = <p>Advanced 2</p>;
                    else if (classEnts[clss].level === "AD3") level = <p>Advanced 3</p>;
                    else if (classEnts[clss].level === "EX1") level = <p>Expert 1</p>;
                    else if (classEnts[clss].level === "EX2") level = <p>Expert 2</p>;
                    else if (classEnts[clss].level === "EX3") level = <p>Expert 3</p>;
                    else if (classEnts[clss].level === "EX4") level = <p>Expert 4</p>;
                    else if (classEnts[clss].level === "MA1") level = <p>Master 1</p>;
                    else if (classEnts[clss].level === "MA2") level = <p>Master 2</p>;
                    else if (classEnts[clss].level === "MA3") level = <p>Master 3</p>;
                    else if (classEnts[clss].level === "MA4") level = <p>Master 4</p>;
                    else if (classEnts[clss].level === "MA5") level = <p>Master 5</p>;
                    else level = <p>{classEnts[clss].level}</p>;
                    toPrint.push(level);

                    // Parse Timing
                    let timing = [];
                    if (classEnts[clss].timing.indexOf(",") !== -1) {
                        let firstPart = classEnts[clss].timing.slice(0, classEnts[clss].timing.indexOf(","));
                        if (firstPart.slice(0, 3) === "Mon") firstPart = "Monday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
                        else if (firstPart.slice(0, 3) === "Tue") firstPart = "Tuesday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
                        else if (firstPart.slice(0, 3) === "Wed") firstPart = "Wednesday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
                        else if (firstPart.slice(0, 3) === "Thu") firstPart = "Thursday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
                        else if (firstPart.slice(0, 3) === "Fri") firstPart = "Friday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
                        else if (firstPart.slice(0, 3) === "Sat") firstPart = "Saturday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
                        else if (firstPart.slice(0, 3) === "Sun") firstPart = "Sunday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);

                        let secondPart = classEnts[clss].timing.substr(classEnts[clss].timing.indexOf(",")+2);
                        timing.push(<p class = "thinner-text">{firstPart}</p>);
                    }
                    else {
                        timing = classEnts[clss].timing;
                    }
                    toPrint.push(timing);
                    
                    let string = "";
                    let timing_string = classEnts[clss] && classEnts[clss].timing ? classEnts[clss].timing : "";
                    let attendance_array = [];
                    timing_string = timing_string.slice(14);
                    while (timing_string.length > 2 && timing_string.indexOf(',') !== -1) {
                    string = timing_string.slice(0, timing_string.indexOf(','));
                    timing_string = timing_string.slice(timing_string.indexOf(',')+2);
                    attendance_array.push(string);
                    }

                    for (const studentReport of classEnts[clss].attendance_data) {
                        if (studentReport.slice(0, studentReport.indexOf(".")) === studentId.studentId) {
                            numberOfClasses ++;
                            let attendance = [];
                            for (let i=0; i<attendance_array.length; i++) {
                                let index1 = findNthDotIndex(studentReport, i+1);
                                let index2 = findNthDotIndex(studentReport, i+2);
                                if (i !== attendance_array.length-1) {
                                    let hi = studentReport.slice(index2-1, index2);
                                    let thing = "";
                                    if (hi === "!") thing = "✓";
                                    if (hi === "?") thing = "X";
                                    if (hi === "M") thing = "Make-up";
                                    attendance.push(<tr>
                                        <td className="cell_smallers">{studentReport.slice(index1+1, index2-1)}</td>
                                        <td className="cell_smallers">{thing}</td>
                                    </tr>);
                                }
                                else {
                                    let hi = studentReport.slice(studentReport.length-1);
                                    let thing = "";
                                    if (hi === "!") thing = "✓";
                                    if (hi === "?") thing = "X";
                                    if (hi === "M") thing = "Make-up";
                                    attendance.push(<tr>
                                    
                                        <td className="cell_smallers">{studentReport.slice(index1+1, studentReport.length-1)}</td>
                                        <td className="cell_smallers">{thing}</td>
                                    </tr>
                                    );
                                }
                            }
                            attendanceReport.push(<div><h2>{toPrint}</h2><table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>{attendance}</tbody>
                                    </table></div>
                                    );
                        }
                        setAttendanceReport(attendanceReport);
                        // TODO: studentReport is string with studentIds (need to find the student first then parse the string). Need to handle case with multiple classes!
                    }
                    }
                }
            }
        }
       
      }, [student, classes]);
    
    
  return (
    <div>
      <h1>Attendance Report for {student && student.first_name + " " + student.last_name}</h1>
      {attendanceReport}
      <h2>Makeup Classes:</h2>
      {makeup.length >= 1 ? (
        makeup
        ) : (
        <p>There are no makeup classes to show within the next week.</p>
        )}      
        <br />
      <h2>Note: This student has changed classes {student ? student.changed_classes_count : "Error Unknown"} times.</h2>
    </div>
  );
};

export default Class;