import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const studentsAdapter = createEntityAdapter({});

const initialState = studentsAdapter.getInitialState();

export const studentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStudents: builder.query({
            query: () => '/students',
            validStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            keepUnusedDataFor: 60,
            transformResponse: (responseData) => {
                const loadedStudents = responseData.map((student) => {
                    student.id = student._id;
                    return student;
                });
                return studentsAdapter.setAll(initialState, loadedStudents);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Students', id: 'LIST' },
                        ...result.ids.map((id) => ({ type: 'Student', id }))
                    ]
                } else {
                    return [{ type: 'Students', id: 'LIST' }]
                }
            }
        }),

        addNewStudent: builder.mutation({
            query: initialStudentData => ({
                url: '/students',
                method: 'POST',
                body: { ...initialStudentData }
            }),
            invalidatesTags: [{ type: 'Student', id: 'LIST' }]
        }),

        updateStudent: builder.mutation({
            query: initialStudentData => ({
                url: '/students',
                method: 'PATCH',
                body: { ...initialStudentData }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Student', id: arg.id }]
        }),

        updateStudentClasses: builder.mutation({
            query: ({ student_id, class_id, action }) => ({
                url: `/students/${student_id}`,
                method: 'PATCH',
                body: { class_id, action }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Student', id: arg.id }]
        }),

        deleteStudent: builder.mutation({
            query: ({ id }) => ({
                url:'/students',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Student', id: arg.id }]
        })

    }),
});

export const {
    useGetStudentsQuery,
    useAddNewStudentMutation,
    useUpdateStudentMutation,
    useUpdateStudentClassesMutation,
    useDeleteStudentMutation
} = studentsApiSlice;

export const selectStudentResult = studentsApiSlice.endpoints.getStudents.select();

const selectStudentData = createSelector(
    selectStudentResult,
    (studentResult) => studentResult.data
);

export const {
    selectAll: selectAllStudents,
    selectById: selectStudentById,
    selectIds: selectStudentIds
} = studentsAdapter.getSelectors((state) => selectStudentData(state) ?? initialState);