import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useRef, useState, useEffect } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { selectStudentById, useUpdateStudentMutation, useGetStudentsQuery, useUpdateStudentClassesMutation } from '../students/studentsApiSlice';
import { selectUserById, useGetUsersQuery, useUpdateUserChildrenMutation } from './usersApiSlice';
import { selectClassById, useGetClassesQuery, useAddNewClassMutation, useUpdateClassStudentsMutation } from '../classes/classesApiSlice';

const enrolledClassesRegex = /[0-9]{3}-[A-Z]{3}-[A-Z]{3}-.{3}.*/i;

const TeacherEditStudent = () => {
	const { studentId } = useParams();
	const student = useSelector((state) => selectStudentById(state, studentId));
	const errRef = useRef(null);
	
    const { data: users} = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
    
      const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch} = useGetStudentsQuery(undefined, {
        pollingInterval: 100,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
        
      const { data: classes} = useGetClassesQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });

	  let enrolled_classes_string = "";
	// Get student's enrolled classes as a string
    if (classes && student) {
        const { ids: classIds, entities: classEntities} = classes;
		const enrolled_classes_bool = classIds.map(classId => {
			for (let j=0; j<student.enrolled_classes.length; j++) {
				if (student.enrolled_classes[j] === classId) {
					return classEntities[classId].group_identifier;
				}
			}
			return 0;
		});
		let numClassesCounter = 0;
        for (let i=0; i<enrolled_classes_bool.length; i++) {
            if (enrolled_classes_bool[i] !== 0) {
                enrolled_classes_string += enrolled_classes_bool[i];
                if (numClassesCounter != student.enrolled_classes.length-1) {
                    enrolled_classes_string += "/";
                }
                numClassesCounter ++;
            }
            
        }
    }
    
      const [updateStudent, {
        isLoading,
        isSuccess,
        isError,
        error,
        reset
    }] = useUpdateStudentMutation();

    const navigate = useNavigate();

    const [lichess, setLichess] = useState('')
    const [validLichess, setValidLichess] = useState(false)
    const [lichessFocus, setLichessFocus] = useState(false)

    const [level, setLevel] = useState('')
    const [validLevel, setValidLevel] = useState(false)
    const [levelFocus, setLevelFocus] = useState(false)

    const submitRoute = '/portal/attendance';
    const rerouteText = 'Return to Attendance';

    useEffect(() => {
        setValidLichess(lichess.length > 0)
    }, [lichess]);

    useEffect(() => {
        setValidLevel(level.length > 0)
    }, [level]);

    const onLichessChanged = e => setLichess(e.target.value);
    const onLevelChanged = e => setLevel(e.target.value);
    
    const onSaveUserClicked = async (e) => {
        e.preventDefault();
        const thingy = await updateStudent({
                id: studentId,
                first_name: student.first_name,
                last_name: student.last_name,
                parent_id: student.parent_id,
                enrolled_classes: student.enrolled_classes,
                email: student.email,
                phone: student.phone,
                changed_classes_count: student.changed_classes_count,
                recommended_level: level,
                lichess_username: lichess
        });
    }

    let canSave;
    canSave = [validLichess, validLevel].every(Boolean) && !isLoading;

    let errmsg;
    if (isError) {
        window.scrollTo(0, 0);
        if (error.status === 409) {
            errmsg = <>Class name is already taken. Please choose another.</>
        } else {
            errmsg = <>An error occurred. Please try again later.</>
        }
    }

    if (isSuccess) {
        window.scrollTo(0, 0);
        return (
            <div className="register">
                <section>
                    <h2>Success!</h2>

                    <button class = "login-button" onClick={() => navigate(submitRoute)}> {rerouteText} </button>
                </section>
            </div>
        )
    }

    const content = (
        <>
        <div class = "whitespace"></div>
        <section class = "register">
            <p ref={errRef} className={isError ? "errmsg" : "offscreen"} aria-live="assertive">{errmsg}</p>
            <form onSubmit={e => e.preventDefault()} class = "login-container">
                <h1 class = "register-title">Edit Student Lichess or Level</h1>

                <label htmlFor="lichess">
                    Lichess Username<br></br>Current: {student && student.lichess_username}
                </label>
                <input
                    type="text"
                    id="lichess"
                    name="lichess"
                    value={lichess}
                    onChange={onLichessChanged}
                    onFocus={() => setLichessFocus(true)}
                    onBlur={() => setLichessFocus(false)}
                    className={lichessFocus ? (validLichess ? "valid" : "invalid") : ""}
                    aria-invalid={!validLichess}
                    aria-describedby="lichess"
                    required
                />
                <p id="lichess" className={lichessFocus && lichess && !validLichess ? "instructions" : "offscreen"}>
                    Invalid Lichess username.
                </p>

                <label htmlFor="level">
                    Student Recommended Level<br></br>Current: {student && student.recommended_level}
                </label>
                <input
                    type="text"
                    id="level"
                    name="level"
                    value={level}
                    onChange={onLevelChanged}
                    onFocus={() => setLevelFocus(true)}
                    onBlur={() => setLevelFocus(false)}
                    className={levelFocus ? (validLevel ? "valid" : "invalid") : ""}
                    aria-invalid={!validLevel}
                    aria-describedby="level"
                    required
                />
                <p id="level" className={levelFocus && level && !validLevel ? "instructions" : "offscreen"}>
                    Invalid level.
                </p>

                <button onClick={onSaveUserClicked} disabled={!canSave}>Save Changes</button>
            </form>       
        </section>
        <div class = "whitespace"></div>
        </>
    )

    return <div className="edit-user">{content}</div>
}

export default TeacherEditStudent