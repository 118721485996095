import useAuth from '../hooks/useAuth'
import { Link } from 'react-router-dom'
import { useGetStudentsQuery } from '../features/students/studentsApiSlice'

const Assessment = () => {
  const { username, role, id } = useAuth();

  const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch} = useGetStudentsQuery(undefined, {
    pollingInterval: 100,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content = [];
  if (studs) {
    const {ids, entities} = studs;
    const stuff = ids.map(hi => {
      if (entities[hi].parent_id === id) return hi;
      else return 0;
    });
    for (const element of stuff) {
      if (element !== 0) content.push(<p>{entities[element].first_name+" "+entities[element].last_name}: {entities[element].recommended_level}</p>);
    }
  }

  return (
    <div>
        <h1>Student Level Assessment</h1>
        <p>View the recommended student levels for the upcoming term here.</p>
        <p>{content}</p>
    </div>
  )
}

export default Assessment