import { Outlet } from 'react-router-dom'

import PortalHeader from './PortalHeader'
import PortalFooter from './PortalFooter'

const PortalLayout = () => {
  return (
    <>
        <PortalHeader />
        <div>
            <Outlet />
        </div>
        <PortalFooter />
    </>
  )
}

export default PortalLayout