import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { selectClassById } from '../classes/classesApiSlice'
import { useGetStudentsQuery } from '../students/studentsApiSlice'
// Imports redux function that gets the classes. Same format for users and students.
// Look at classesApiSlice for the things. Always useXXXX, where XXX is the function with first letter capital.
// Data, is actual data. isLoading, isSuccess, isError is redux processes. Whatever it is will be true, automataically provided by redux. error is the actual error if there's an error.
import StudentAttendanceRow from './StudentAttendanceRow.js'

function checkIfSameWeek(targetDateStr) {
    // Parse the target date string in the "M/D" format
    const [targetMonth, targetDay] = targetDateStr.split('/').map(Number);
  
    // Create Date objects for the current date and the target date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const targetDate = new Date(currentYear, targetMonth - 1, targetDay);
  
    // Calculate the start of the current week as the current date
    const currentWeekStart = new Date(currentDate);
  
    // Calculate the end of the current week, accounting for month and year transitions
    const currentWeekEnd = new Date(currentWeekStart);

    currentWeekEnd.setDate(currentWeekStart.getDate() + 7);

    // Check if the target date falls within the same week
    return (
      (targetDate >= currentWeekStart && targetDate <= currentWeekEnd) ||
      (targetDate >= currentWeekStart && (targetDate.getMonth() !== currentMonth || targetDate.getFullYear() !== currentYear))
    );
  }
  

const StudentAttendance = ({classId}) => {
    const clss = useSelector((state) => selectClassById(state, classId));

    const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch} = useGetStudentsQuery(undefined, {
        pollingInterval: 100,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });

    const { ids: studentIds, entities } = studs ?? [];
    let makeup_students = [];

    const timingHeaders = [];
    let string = "";
    let timing_string = clss && clss.timing ? clss.timing : "";
    let attendance_array = [];
    timing_string = timing_string.slice(14);
    while (timing_string.length > 2 && timing_string.search(',') !== -1) {
      string = timing_string.slice(0, timing_string.search(','));
      timing_string = timing_string.slice(timing_string.search(',')+2);
      attendance_array.push(<th>{string}</th>);
    }
    if (clss && studs) {
        for (const element of clss.attendance_data) {
            if (element[0] === "M" && checkIfSameWeek(element.substr(1, element.indexOf(".")-1))) {
                makeup_students.push(<p>Makeup: {entities[element.substr(element.indexOf(".")+1)].first_name + " " + entities[element.substr(element.indexOf(".")+1)].last_name }</p>);
            }
        }
    }

    let tableContent = [];
    if (studentIds !== undefined && clss !== undefined && studentIds.length > 0) tableContent = studentIds.map((studentId) => {
        const combinedKey = `${studentId}-${classId}`;
        if (clss.enrolled_students.includes(studentId)) return <StudentAttendanceRow key={combinedKey} studentId={studentId} numDates = {attendance_array.length} clss = {clss} classId = {classId}/>
    });

    let content;
    const navigate = useNavigate();
       
        content = (
            <div class = "table-page">
                <br />
                <div class="right-align">
                    <h1>Class: {clss && clss.group_identifier}</h1>
                </div>
                
                <table>
                    <thead>
                        <tr>
                            <th>First</th>
                            <th>Last</th>
                            <th>Lichess</th>
                            <th>Level</th>
                            <th>Edit Lichess/Level</th>
                            {attendance_array}
                        </tr>
                    </thead>
                    <tbody>{tableContent}</tbody>
                </table>

                {makeup_students}
            </div>
        );

    return content;
}

export default StudentAttendance