import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserById, useUpdateUserMutation } from './usersApiSlice'

const User = ({ userId, searchQuery }) => {
    const [tempPwd, setTempPwd] = useState("");

    const user = useSelector((state) => selectUserById(state, userId));
    const navigate = useNavigate();

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation();

    useEffect(() => {
        if (isSuccess) {
            alert(`Password reset for ${user.username}! Their new password is ${tempPwd}. Please note this down somewhere as it will not be shown again, and notify the individual to log in with this password and change it as soon as possible.`);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            alert(`Error resetting password: ${error.error}!`);
        }
    }, [isError]);

    const handlePwdReset = async (e) => {
        e.preventDefault();

        if (window.confirm(`Are you sure you want to reset ${user.username}'s password? This is an irreversible action.`)) {
            const pwd = Math.random().toString(36).slice(-8);
            setTempPwd(pwd);
            await updateUser({
                id: user.id,
                username: user.username,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                password: pwd,
                role: user.role
            });
        }
    }

    if (user) {
        if (searchQuery !== "") {
            if (!user.username.toLowerCase().includes(searchQuery.toLowerCase())
                && !user.first_name.toLowerCase().includes(searchQuery.toLowerCase())
                && !user.last_name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return null;
            }
        }

        const handleEdit = () => navigate(`/portal/${userId}`);
        const userRolesString = user.role;

        return (
            <tr className={`row ${user ? (userId % 2 === 0 ? 'even' : '') : ''}`}>
                <td className="cell">{user.username}</td>
                <td className="cell">{user.first_name}</td>
                <td className="cell">{user.last_name}</td>
                <td className="cell">{user.email}</td>
                <td className="cell">{user.phone}</td>
                <td className="cell">{userRolesString}</td>
                <td className="cell">
                    <button className="button" onClick={handleEdit}>Edit</button>
                </td>
                <td className="cell">
                    <button className={`reset-button`} onClick={(e) => handlePwdReset(e)}>Reset Password</button>
                </td>
                </tr>
        )
    } else return null;
}

export default User