import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetStudentsQuery } from '../students/studentsApiSlice';
import { useUpdateClassMutation, selectClassById } from './classesApiSlice';
import { useUpdateStudentMutation } from '../students/studentsApiSlice';

const StudentSelectionBox = ({ user, classId, enrolledStudents, dates, onClose }) => {
  const [selectedStudent, setSelectedStudent] = useState(""); // State to track the selected student
  const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch } = useGetStudentsQuery(undefined, {
    pollingInterval: 100,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const clss = useSelector((state) => selectClassById(state, classId));

  const [updateClass, {
    isLoading: classStudentsUpdateLoading,
    isSuccess: classStudentsUpdateSuccess,
    isError: classStudentsUpdateErrorBool,
    error: classStudentsUpdateError,
  }] = useUpdateClassMutation();

  const [updateStudent, {
    isLoading: studentUpdateLoading,
    isSuccess: studentUpdateSuccess,
    isError: studentUpdateErrorBool,
    error: studentUpdateError,
  }] = useUpdateStudentMutation();

  useEffect(() => {
    if (classStudentsUpdateSuccess && studentUpdateSuccess) onClose();
  }, [classStudentsUpdateSuccess, studentUpdateSuccess]);

  const { ids, entities } = studs;
  // Handle the selection of a student
  const handleStudentSelect = (event) => {
    setSelectedStudent(event.target.value);
  };

  // Handle the swap action
  const handleSwapStudent = async (e) => {
    e.preventDefault();

    // check to make sure student has not changed classes more than 2 times
    const student = entities[selectedStudent];
    if (student.changed_classes_count >= 2) {
      alert("This student has already changed classes twice, which is the maximum allowed. Please contact ccycservice@gmail.com with your name, your child's name, and your email to request to change classes again.");
      return;
    }
    let dateslist = dates.split(',').map(date => date.trim());

    // Check for next available date
    let nextDate = "";
    let today = new Date();
    let endLoop = false;

    while (!endLoop) {
      let tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1); // Add 1 day

      // Check if we've moved to the next month
      if (tomorrow.getDate() === 1) {
        tomorrow.setDate(1); // Set day to 1 when moving to the next month
        tomorrow.setMonth(today.getMonth() + 1);
      }

      var dd = String(tomorrow.getDate()).padStart(2, '0');
      var mm = String(tomorrow.getMonth() + 1).padStart(2, '0'); // January is 0!
      console.log(dd, mm, dateslist);
      if (mm[0] === "0") mm = mm[1]; // Get rid of initial 0's
      if (dd[0] === "0") dd = dd[1];
      let tomorrowDate = mm + '/' + dd;
      console.log(dateslist, tomorrowDate);
      if (dateslist.includes(tomorrowDate)) {
        console.log("hi");
        nextDate = tomorrowDate;
        endLoop = true;
      }

      // Update today for the next iteration
      today = tomorrow;
    }

    // Update attendance array for the next available date (CAN'T DO THIS CAUSE WHICH CLASS DO THEY GET RID OF)
    let attendanceArray = [...clss.attendance_data];
    attendanceArray.push("M" + nextDate + "." + selectedStudent);

    await updateClass({
      id: classId,
      group_identifier: clss.group_identifier,
      level: clss.level,
      teacher_id: clss.teacher_id,
      teacher: clss.teacher,
      timing: clss.timing,
      attendance_data: attendanceArray // need date in this
    });

    // Update student changed classes count +1
    let updatedChangedClassesCount = student.changed_classes_count + 1;
    await updateStudent({
      id: student.id,
      first_name: student.first_name,
      last_name: student.last_name,
      parent_id: student.parent_id,
      email: student.email,
      phone: student.phone,
      changed_classes_count: updatedChangedClassesCount
    });
  };

  return (
    <div className="student-selection-box">
      <h3>Select a student to swap:</h3>
      <select onChange={handleStudentSelect} value={selectedStudent}>
        <option value="">-- Select a student --</option>
        {studs && user.children_ids.map((student, index) => (!enrolledStudents.includes(student) && <option key={index} value={student}>
          {entities[student].first_name + " " + entities[student].last_name}
        </option>
        ))}
      </select>
      <br></br>
      <button onClick={handleSwapStudent}>Swap</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};

export default StudentSelectionBox;