import React from 'react';

const LAResults = ({level, onPrevious, onNext }) => {
  return (
    <div>
      <h1>Results</h1> 
      <p>Congratulations on completing the Level Assessment Tool! Your level is {level}.</p>
      <p>Please click submit to send your results to the email you provided.</p>
      <button onClick={onNext}>Submit</button>
    </div>
  );
};

export default LAResults;