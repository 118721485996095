import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LA2 = ({setLevel, rating, setRating, onPrevious, onNext }) => {
    const [playerData, setPlayerData] = useState(null);
    const [username, setUsername] = useState('');

    useEffect(() => {
      if (playerData === null) {
        setRating(400);
      }
      else if (playerData.chess_rapid?.record?.win+playerData?.chess_rapid?.record?.loss+playerData?.chess_rapid?.record?.draw >= 15 && playerData.chess_daily?.record?.win+playerData.chess_daily?.record?.loss+playerData.chess_daily?.record?.draw >= 20 && playerData.chess_blitz?.record?.win+playerData.chess_blitz?.record?.loss+playerData.chess_blitz?.record?.draw >= 20) {
        setRating(playerData.chess_rapid?.last?.rating*0.5+playerData.chess_daily?.last?.rating*0.35+playerData.chess_blitz?.last?.rating*0.15);
      }
      else if (playerData.chess_rapid?.record?.win+playerData.chess_rapid?.record?.loss+playerData.chess_rapid?.record?.draw >= 10 && playerData.chess_blitz?.record?.win+playerData.chess_blitz?.record?.loss+playerData.chess_blitz?.record?.draw >= 15) {
        setRating(playerData.chess_rapid?.last?.rating*0.7+playerData.chess_blitz?.last?.rating*0.3);
      }
      else if (playerData.chess_rapid?.record?.win+playerData.chess_rapid?.record?.loss+playerData.chess_rapid?.record?.draw >= 8) {
        setRating(playerData.chess_rapid?.last?.rating);
      }
      else if (playerData.chess_blitz?.record?.win+playerData.chess_blitz?.record?.loss+playerData.chess_blitz?.record?.draw >= 8) {
        setRating(playerData.chess_blitz?.last?.rating);
      }
      else {
        setRating(400);
      }
    }, [playerData]);

    const handleNext = (e) => {
      e.preventDefault();
      onNext();
    }

    useEffect(() => {
        const fetchPlayerData = async () => {
          try {
            const response = await axios.get(`https://api.chess.com/pub/player/${username}/stats`);
            setPlayerData(response.data);
          } catch (error) {
            console.error('Error fetching player data:', error);
          }
        };
    
        fetchPlayerData();
      }, [username]);

    const handleChange = (e) => {
        const {value} = e.target;
        setUsername(value);
      };

  return (
    <div>
      <h1>Level Assessment</h1>
      <p>Please enter your chess.com username.</p>
      <p class ="makethisthinner">If you do not have a chess.com account, please skip this step or create an account and play at least 8 games at a rapid (10 minutes per side) time control.</p>
      <div>
        <label>Chess.com Username:     </label>
        <input type="text" name="username" value={username} onChange={handleChange}/>
      </div>
      {playerData ? (
        <div>
          <h2>{playerData.username}</h2>
          {/* You can display other data from the API here */}
        </div>
      ) : (
        <p>Loading player data...</p>
      )}
      <button class = "la-button" onClick={onPrevious}>Previous</button>
      <button class = "la-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default LA2;