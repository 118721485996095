import { Route, Routes } from "react-router-dom";

import { ROLES } from "./config/roles";
import Layout from "./components/Layout";
import Public from "./components/Public";
import Missing404 from "./components/Missing404";
import Login from "./features/auth/Login";
import Register from "./features/auth/Register";
import LevelAssessmentHome from "./components/LevelAssessmentHome";
import PortalLayout from "./components/PortalLayout";
import PortalHome from "./features/auth/PortalHome";
import LA1 from './components/LA1';
import LA2 from './components/LA2';
import LA3B from './components/LA3B';
import LA3M from './components/LA3M';
import LA3A from './components/LA3A';
import LA3E from './components/LA3E';
import LAResults from './components/LAResults';
import ClassesList from './features/classes/ClassesList';
import ViewClasses from './features/classes/ViewClasses';
import SwapClasses from './features/classes/SwapClasses';
import ViewStudent from './features/students/ViewStudent';
import AddClass from "./features/classes/AddClass";
import EditClass from "./features/classes/EditClass";
import Assessment from './components/Assessment';
import ImportCSV from './features/auth/ImportCSV';
import UsersList from "./features/users/UsersList";
import Attendance from "./features/users/Attendance";
import TeacherEditStudent from "./features/users/TeacherEditStudent";
import StudentsList from "./features/students/StudentsList";
import AddStudent from "./features/students/AddStudent";

// insert all features imports here
import EditSelf from "./features/users/EditSelf";
import EditUser from "./features/users/EditUser";
import EditStudent from "./features/students/EditStudent";
import Prefetch from './features/auth/Prefetch';
import PersistLogin from './features/auth/PersistLogin';
import RequireAuth from './features/auth/RequireAuth';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        {/* Public Routes */}
        <Route index element={<Public />} />
        <Route path="levelassess" element={<LevelAssessmentHome />} />
        <Route path="LA1" element={<LA1 />} />
        <Route path="LA2" element={<LA2 />} />
        <Route path="LA3B" element={<LA3B />} />
        <Route path="LA3M" element={<LA3M />} />
        <Route path="LA3A" element={<LA3A />} />
        <Route path="LA3E" element={<LA3E />} />
        <Route path="LAResults" element={<LAResults />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        {/* End of Public Routes */}


        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />} >
            <Route element={<Prefetch />} >

              <Route path="portal" element={<PortalLayout />} >

                <Route index element={<PortalHome />} />

                <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />} >
                  {/* insert all Admin-only routes here */}
                  <Route path="classes-list" element={<ClassesList />}/>
                  <Route path="classes-list/:classId" element={<EditClass />} />
                  <Route path="users-list" element = {<UsersList />} />
                  <Route path="import-csv" element={<ImportCSV />} />
                  <Route path="add-class" element={<AddClass />} />
                  <Route path="add-student" element={<AddStudent />} />
                  <Route path="students-list" element={<StudentsList />} />
                  <Route path="students-list/view/:studentId" element={<ViewStudent />} />
                  <Route path="students-list/:studentId" element={<EditStudent />} />
                  <Route path=":id" element={<EditUser />} />
    
                  {/*Don't need to import ClassTableRow because it's not rendered here, and it's imported in ClassesList*/}
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.PARENT]} />} >
                  {/* insert all Parent-only routes here */}
                  <Route path="view-classes" element={<ViewClasses />} />
                  <Route path="swap-classes" element={<SwapClasses />} />
                  <Route path="assessment" element={<Assessment />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.TEACHER]} />} >
                  {/* insert all Teacher-only routes here */}
                  <Route path="classes-list" element={<ClassesList />} />
                  <Route path="users-list" element = {<UsersList />} />
                  <Route path="attendance" element = {<Attendance />} />
                  <Route path="attendance/:studentId" element={<TeacherEditStudent />} />
                  {/*Don't need to import ClassTableRow because it's not rendered here, and it's imported in ClassesList*/}
                </Route>

                <Route path="edit-self">
                  <Route index element={<EditSelf />} />
                </Route>

              </Route>

            </Route>
          </Route>
        </Route>
        {/* End of Protected Routes */}

        <Route path="*" element={<Missing404 />} />

      </Route>
    </Routes>
  );
}

export default App;
