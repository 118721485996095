import { useRef, useState, useEffect } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useAddNewClassMutation, useGetClassesQuery, useUpdateClassStudentsMutation } from './classesApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import { useGetStudentsQuery, useUpdateStudentClassesMutation } from '../students/studentsApiSlice'
import { isDOMComponent } from "react-dom/test-utils";
const NAME_REGEX = /[0-9]{3}-[A-Z]{3}-[A-Z]{3}-.{3}/i;

const AddClass = () => {

    const errRef = useRef(null);
    const { data: users} = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });

    const { data: classes} = useGetClassesQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
    
    const { data: studs, isLoading: bruh, isSuccess: pleaseWORK, refetch} = useGetStudentsQuery(undefined, {
        pollingInterval: 100,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });
      const [updateStudentClasses, {
        isLoading: studentLoading,
        isSuccess: studentSuccess,
        isError: studentErrorBool,
        error: studentError,
      }] = useUpdateStudentClassesMutation();
      
      const [updateClassStudents, {
        isLoading: classStudentsUpdateLoading,
        isSuccess: classStudentsUpdateSuccess,
        isError: classStudentsUpdateErrorBool,
        error: classStudentsUpdateError,
      }] = useUpdateClassStudentsMutation();

    const [addClass, {
        isSuccess,
        isLoading,
        isError,
        error
    }] = useAddNewClassMutation();

    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [validName, setValidName] = useState(false)
    const [nameFocus, setNameFocus] = useState(false)

    const [teacher, setTeacher] = useState('')
    const [validTeacher, setValidTeacher] = useState(false)
    const [teacherFocus, setTeacherFocus] = useState(false)

    const [timing, setTiming] = useState('')
    const [validTiming, setValidTiming] = useState(false)
    const [timingFocus, setTimingFocus] = useState(false)

    const submitRoute = '/portal/classes-list';
    const rerouteText = 'Return to Classes List';

    useEffect(() => {
        setValidName(NAME_REGEX.test(name))
    }, [name]);

    useEffect(() => {
        const timingRegex = /[A-Z]{3}[0-9]{4}-[0-9]{4},.*/i;
        setValidTiming(timingRegex.test(timing));
    }, [timing]);

    useEffect(() => {
        setValidTeacher(teacher.length > 0);
    }, [teacher]);

    useEffect(() => {
        const hi = async () => {
            const {ids, entities} = studs;
            const {ids: clssIds, entities: clssEnts} = classes;
    
            const classIds = clssIds.map(id => {
                if (clssEnts[id].group_identifier === name && clssEnts[id].teacher === teacher) {
                    return id;
                }
                else return 0;
            })
            let classId = 0;
            for (const element of classIds) {
                if (element !== 0) classId = element;
            }
    
            const studentIs = ids.map(id => {
                let classFound = false;
                for (const element of entities[id].enrolled_classes) {
                    if (classId === element) {
                        classFound = true;
                    }
                }
                if (classFound) return id;
                else return 0;
            })
            const studentIds = [];
            for (const element of studentIs) {
                if (element !== 0) {
                    studentIds.push(element);
                }
            }
    
            for (const element of studentIds) {
                    const response = await updateStudentClasses({
                      student_id: element,
                      class_id: classId,
                      action: "add"
                    });
                    const hi = await updateClassStudents({
                      class_id: classId,
                      student_id: element,
                      action: "add"
                    });
            }
        }
        hi();
    }, [classes]);

    const onNameChanged = e => setName(e.target.value);
    const onTeacherChanged = e => setTeacher(e.target.value);
    const onTimingChanged = e => setTiming(e.target.value);

    const onSaveUserClicked = async (e) => {
        e.preventDefault();

        let level = null;
        let clsslevel = name.substr(-3, 3);
        if (clsslevel === "BEG") level = "Beginner";
        else if (clsslevel === "NEW") level = "Newbie";
        else if (clsslevel === "EXP") level = "Expert Prac";
        else if (clsslevel === "MAP") level = "Master Prac";
        else if (clsslevel === "MED") level = "Medium";
        else if (clsslevel === "AD1") level = "Advanced 1";
        else if (clsslevel === "AD2") level = "Advanced 2";
        else if (clsslevel === "AD3") level = "Advanced 3";
        else if (clsslevel === "EX1") level = "Expert 1";
        else if (clsslevel === "EX2") level = "Expert 2";
        else if (clsslevel === "EX3") level = "Expert 3";
        else if (clsslevel === "EX4") level = "Expert 4";
        else if (clsslevel === "MA1") level = "Master 1";
        else if (clsslevel === "MA2") level = "Master 2";
        else if (clsslevel === "MA3") level = "Master 3";
        else if (clsslevel === "MA4") level = "Master 4";
        else if (clsslevel === "MA5") level = "Master 5";
        else level = clsslevel;

        const {ids, entities} = users;
        const teacherIds = ids.map(id => {
            if (entities[id].role === "Teacher" && entities[id].first_name === teacher) {
              return id;
            }
            return 0;
          })
          let teacherId = 0;
          for (const id of teacherIds) {
            if (id !== 0) teacherId = id;
          }

        await addClass({
            group_identifier: name,
            level: level,
            teacher: teacher,
            timing: timing,
            teacher_id: teacherId
        });
    }

    let canSave;
    canSave = [validName, validTeacher, validTiming].every(Boolean) && !isLoading;

    let errmsg;
    if (isError) {
        window.scrollTo(0, 0);
        if (error.status === 409) {
            errmsg = <>Class name is already taken. Please choose another.</>
        } else {
            errmsg = <>An error occurred. Please try again later.</>
        }
    }

    if (isSuccess) {
        window.scrollTo(0, 0);
        return (
            <div className="register">
                <section>
                    <h2>Success!</h2>

                    <button class = "login-button" onClick={() => navigate(submitRoute)}> {rerouteText} </button>
                </section>
            </div>
        )
    }

    const content = (
        <>
        <div class = "whitespace"></div>
        <section class = "register">
            <p ref={errRef} className={isError ? "errmsg" : "offscreen"} aria-live="assertive">{errmsg}</p>
            <form onSubmit={e => e.preventDefault()} class = "login-container">
                <h1 class = "register-title">Add Class</h1>

                <label htmlFor="group-identifier">
                    Group Identifier:
                </label>
                <input
                    type="text"
                    id="group-identifier"
                    name="group-identifier"
                    value={name}
                    onChange={onNameChanged}
                    onFocus={() => setNameFocus(true)}
                    onBlur={() => setNameFocus(false)}
                    className={nameFocus ? (validName ? "valid" : "invalid") : ""}
                    aria-invalid={!validName}
                    aria-describedby="name"
                    required
                />
                <p id="uidnote" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
                    Group Identifier should be in the form 233-MIK-MON-AD3.
                </p>

                <label htmlFor="teacher">
                        Teacher (First name only)
                    </label>
                    <input
                        type="text"
                        id="teacher"
                        onChange={onTeacherChanged}
                        value={teacher}
                        required
                        aria-invalid={validTeacher ? "false" : "true"}
                        aria-describedby="teachernote"
                        onFocus={() => setTeacherFocus(true)}
                        onBlur={() => setTeacherFocus(false)}
                    />
                    <p id="teachernote" className={teacherFocus && !validTeacher ? "instructions" : "offscreen"}>
                        First name only.
                    </p>

                <label htmlFor="timing">
                    Timing
                </label>
                <input
                    type="text"
                    id="timing"
                    name="timing"
                    value={timing}
                    onChange={onTimingChanged}
                    onFocus={() => setTimingFocus(true)}
                    onBlur={() => setTimingFocus(false)}
                    className={timingFocus ? (validTiming ? "valid" : "invalid") : ""}
                    aria-invalid={!validTiming}
                    aria-describedby="timingnote"
                    required
                />
                <p id="timingnote" className={timingFocus && !validTiming ? "instructions" : "offscreen"}>
                    Timing should be in the form Sat1815-1945, 9/9, 9/16, 9/23, 9/30, 10/14, 10/21, 10/28, 11/4, 11/11, 11/18, 11/25, 12/2, 12/9, 12/16, 12/23, 1/13
                </p>

                <button onClick={onSaveUserClicked} disabled={!canSave}>Save Changes</button>
            </form>
        </section>
        <div class = "whitespace"></div>
        </>
    )

    return <div className="edit-user">{content}</div>
}
export default AddClass