import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectClassById, useUpdateClassStudentsMutation, useDeleteClassMutation } from "./classesApiSlice"; //Another redux function that takes a class and selects the one we want with the specific ID
import { useGetStudentsQuery } from "../students/studentsApiSlice";


const ClassTableRow = ({ classId, searchQuery }) => {
    const clss = useSelector((state) => selectClassById(state, classId)); // Pulling the one with the right ID out of all the class states
    const navigate = useNavigate();
    const [deleteClass, {
        isLoading: deleteClassLoading,
        isSuccess: deleteClassSuccess,
        isError: deleteClassErrorBool,
        error: deleteClassError
    }] = useDeleteClassMutation();

    const [updateClassStudents, {
        isLoading: classStudentsUpdateLoading,
        isSuccess: classStudentsUpdateSuccess,
        isError: classStudentsUpdateErrorBool,
        error: classStudentsUpdateError,
    }] = useUpdateClassStudentsMutation();

    const handleDelete = async (e) => {
        e.preventDefault();

        if (window.confirm("Are you sure you want to delete this class?")) {
            await deleteClass({
                id: classId
            });
            for (let i=0; i<clss.enrolled_students.length; i++) {
                await updateClassStudents({
                    class_id: classId,
                    student_id: clss.enrolled_students[i],
                    action: "remove"
                  });
            }
        }
        
    }
    const { data: studs, isLoading, isSuccess} = useGetStudentsQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });

    if (clss && studs) {
        const { ids: studentIds, entities: students } = studs;
        const handleEdit = () => {
            navigate(`/portal/classes-list/${classId}`);
            navigate(0);
        }

        if (searchQuery !== "") {
            if (!clss.teacher.toLowerCase().includes(searchQuery.toLowerCase())
            && !clss.group_identifier.toLowerCase().includes(searchQuery.toLowerCase())
            && !clss.timing.toLowerCase().includes(searchQuery.toLowerCase())
            && !clss.level.toLowerCase().includes(searchQuery.toLowerCase())) {
                return null; // If none of them match, return empty cause we don't want to render it.
            }
            
        }

        // Parse Enrolled Students
        const enrolled_students_array_temp = studentIds?.map(studentId => {
            for (let k=0; k < clss.enrolled_students.length; k++) {
              if (studentId === clss.enrolled_students[k]) {
                return students[studentId].first_name+" "+students[studentId].last_name;
              }
            }
            return 0;
          });
        let enrolled_students_array = [];
        for (let k=0; k<enrolled_students_array_temp.length; k++) {
            if (enrolled_students_array_temp[k] !== 0) {
                enrolled_students_array.push(enrolled_students_array_temp[k]);
            }
        }
        let enrolled_students = [];
        enrolled_students.push(<p class = "thinner-text">{"(Student Count: "+ enrolled_students_array.length.toString()+")"}</p>);
        //enrolled_students.push(<br></br>);
        for (let i=0; i<enrolled_students_array.length; i++) {
            enrolled_students.push(<p class = "thinner-text">{enrolled_students_array[i]}</p>);
            //if (i !== clss.enrolled_students.length-1) enrolled_students.push(<br></br>);
        }

        // Parse Level
        let level = null;
        if (clss.level === "BEG") level = <p>Beginner</p>;
        else if (clss.level === "NEW") level = <p>Newbie</p>;
        else if (clss.level === "EXP") level = <p>Expert Prac</p>;
        else if (clss.level === "MAP") level = <p>Master Prac</p>;
        else if (clss.level === "MED") level = <p>Medium</p>;
        else if (clss.level === "AD1") level = <p>Advanced 1</p>;
        else if (clss.level === "AD2") level = <p>Advanced 2</p>;
        else if (clss.level === "AD3") level = <p>Advanced 3</p>;
        else if (clss.level === "EX1") level = <p>Expert 1</p>;
        else if (clss.level === "EX2") level = <p>Expert 2</p>;
        else if (clss.level === "EX3") level = <p>Expert 3</p>;
        else if (clss.level === "EX4") level = <p>Expert 4</p>;
        else if (clss.level === "MA1") level = <p>Master 1</p>;
        else if (clss.level === "MA2") level = <p>Master 2</p>;
        else if (clss.level === "MA3") level = <p>Master 3</p>;
        else if (clss.level === "MA4") level = <p>Master 4</p>;
        else if (clss.level === "MA5") level = <p>Master 5</p>;
        else level = <p>{clss.level}</p>;

        // Parse Timing
        let timing = [];
        if (clss.timing.search(",") !== -1) {
            let firstPart = clss.timing.slice(0, clss.timing.search(","));
            if (firstPart.slice(0, 3) === "Mon") firstPart = "Monday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
            else if (firstPart.slice(0, 3) === "Tue") firstPart = "Tuesday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
            else if (firstPart.slice(0, 3) === "Wed") firstPart = "Wednesday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
            else if (firstPart.slice(0, 3) === "Thu") firstPart = "Thursday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
            else if (firstPart.slice(0, 3) === "Fri") firstPart = "Friday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
            else if (firstPart.slice(0, 3) === "Sat") firstPart = "Saturday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);
            else if (firstPart.slice(0, 3) === "Sun") firstPart = "Sunday " + firstPart.slice(3, 5) + ":" + firstPart.slice(5, 10) + ":" + firstPart.slice(10);

            let secondPart = clss.timing.substr(clss.timing.search(",")+2);
            timing.push(<p class = "thinner-text">{firstPart}</p>);
            timing.push(<p class = "thinner-text">{secondPart}</p>);
        }
        else {
            timing = clss.timing;
        }

        // Parse Teacher and Group Identifier
        let teacherandgroup = [];
        teacherandgroup.push(<p class = "thinner-text">{clss.group_identifier}</p>);
        teacherandgroup.push(<p class = "thinner-text">{clss.teacher}</p>);

        // tr = table row, td = table data
        return (
            <tr>
                <td className="cell_smaller">{level}</td>
                <td className="cell_smaller">{timing}</td>
                <td className="cell_smaller">{teacherandgroup}</td>
                <td className="cell_smaller">{enrolled_students}</td>
                <td className="cell">
                    <button className="button" onClick={handleEdit}>Edit Class</button>
                </td>
                <td className="cell">
                    <button className="button" onClick={handleDelete}>Delete Class</button>
                </td>
            </tr>
        )
    }
    else {
        return null;
    }
}

export default ClassTableRow