import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserById } from './usersApiSlice'
import useAuth from '../../hooks/useAuth'

import EditUserForm from './EditUserForm'

const EditSelf = () => {
	const { id } = useAuth();
	const user = useSelector((state) => selectUserById(state, id));
	const content = user ? <EditUserForm user={user} editingAll={false}/> : <div>Loading...</div>
	return content;
}

export default EditSelf