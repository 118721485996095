import React, { useState, useEffect }  from 'react';

const LA3E = ({done, setDone, level, setLevel, onPrevious, onSubmit }) => {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [answer5, setAnswer5] = useState('');
  const [answer6, setAnswer6] = useState('');
  const [answer7, setAnswer7] = useState('');
  const [answer8, setAnswer8] = useState('');
  const [answer9, setAnswer9] = useState('');
  const [answer10, setAnswer10] = useState('');
  const [answer11, setAnswer11] = useState('');
  const [answer12, setAnswer12] = useState('');
  const [answer13, setAnswer13] = useState('');
  const [answer14, setAnswer14] = useState('');
  const [answer15, setAnswer15] = useState('');
  const [answer16, setAnswer16] = useState('');

  const [score, setScore] = useState(0);

  const handleSelect1 = (value) => {
    setAnswer1(value);
  };
  const handleSelect2 = (value) => {
    setAnswer2(value);
  };
  const handleSelect3 = (value) => {
    setAnswer3(value);
  };
  const handleSelect4 = (value) => {
    setAnswer4(value);
  };
  const handleSelect5 = (value) => {
    setAnswer5(value);
  };
  const handleSelect6 = (value) => {
    setAnswer6(value);
  };
  const handleSelect7 = (value) => {
    setAnswer7(value);
  };
  const handleSelect8 = (value) => {
    setAnswer8(value);
  };
  const handleSelect9 = (value) => {
    setAnswer9(value);
  };
  const handleSelect10 = (value) => {
    setAnswer10(value);
  };
  const handleSelect11 = (value) => {
    setAnswer11(value);
  };
  const handleSelect12 = (value) => {
    setAnswer12(value);
  };
  const handleSelect13 = (value) => {
    setAnswer13(value);
  };
  const handleSelect14 = (value) => {
    setAnswer14(value);
  };
  const handleSelect15 = (value) => {
    setAnswer15(value);
  };
  const handleSelect16 = (value) => {
    setAnswer16(value);
  };

  useEffect(() => {
  let newScore = 0;
  if (answer1.match(/.*Nb4.*/) || answer1.match(/.*nb4.*/)) newScore+=2;
  if (answer1.match(/.*Nb4.*Nc2\+.*/) || answer1.match(/.*nb4.*nc2\+.*/)) newScore+=2;
  if (answer1.match(/.*Nb4.*Nc2\+.*Nd4.*/) || answer1.match(/.*nb4.*nc2\+.*nd4.*/)) newScore+=2;
  if (answer1.match(/.*Nb4.*Nc2\+.*Nd4.*Kc2.*/) || answer1.match(/.*nb4.*nc2\+.*nd4.*kc2.*/)) newScore++;
  if (answer1.match(/.*Nb4.*Nc2\+.*Nd4.*Kc2.*Ne2.*/) || answer1.match(/.*nb4.*nc2\+.*nd4.*kc2.*ne2.*/)) newScore++;
  if (answer1.match(/.*Nb4.*Nc2\+.*Nd4.*Kc2.*Ne2.*Nc1.*/) || answer1.match(/.*nb4.*nc2\+.*nd4.*kc2.*ne2.*nc1.*/)) newScore++;
  if (answer1.match(/.*Nb4.*Nc2\+.*Nd4.*Kc2.*Ne2.*Nc1.*Nb3#.*/) || answer1.match(/.*nb4.*nc2\+.*nd4.*kc2.*ne2.*nc1.*nb3#.*/) || answer1.match(/Nb4 Nc2\+ Nd4 Kc2 Ne2 Nc1 Nb3.*/)) newScore++;
  if (answer2.match(/.*Nc7\+.*/) || answer2.match(/.*nc7\+.*/)) newScore+=3;
  if (answer2.match(/.*Nc7\+.*Bd4\+.*/) || answer2.match(/.*nc7\+.*bd4\+.*/)) newScore+=3;
  if (answer2.match(/.*Nc7\+.*Bd4\+.*Nb5+.*/) || answer2.match(/.*nc7\+.*bd4\+.*nb5+.*/)) newScore+=2;
  if (answer3 === 'Qa6' || answer3 === 'qa6') newScore += 6;
  if (answer4.match(/.*Rc3+.*Ka4.*/i)) newScore+=1;
  if (answer4.match(/.*Rc8.*/i)) newScore+=4;
  if (answer4.match(/.*Qa5.*Ra8.*/i)) newScore+=2;
  if (answer4.match(/.*Qa5.*Ra8.*Qxa8.*Nb6+.*/i)) newScore+=1;
  if (answer4.match(/.*Qxc8.*Nb6+.*/i)) newScore+=2;
  if (answer4.match(/.*Qxh4.*Rc4+.*/i)) newScore+=2;
  if (answer4.match(/.*Qxh4.*Rc4+.*Qxc4.*Nb6+.*/i)) newScore+=1;
  if (answer4.match(/.*Qd7.*Nb6+.*/i)) newScore+=2;
  if (answer5 === 'b') newScore+=6;
  if (answer6 === 'Qh5' || answer6 === 'qh5') newScore+=6;
  if (answer7 === 'Rc8' || answer7 === 'rc8') newScore+=6;
  if (answer8.match(/.*Bxe6.*/i)) newScore+=4;
  if (answer8.match(/.*Rh8+.*/i)) newScore+=4;
  if (answer9 === 'Re7' || answer9 === 're7') newScore+=6;
  if (answer10.match(/.*c8=R+.*/i)) newScore+=1;
  if (answer10.match(/.*c8.*R+.*/i)) newScore+=3;
  if (answer10.match(/.*Kb3.*/i)) newScore+=4;
  if (answer11.match(/.*Rh8.*/i)) newScore+=6;
  if (answer12.match(/.*Na4.*/i)) newScore+=6;

  setScore(newScore);
}, [answer1, answer2, answer3, answer4, answer5, answer6, answer7, answer8, answer9, answer10, answer11, answer12, answer13, answer14, answer15, answer16]);

  const handleSubmit = () => { // Score out of 91
    if (score >= 50) {
      setLevel(100);
      setDone(1000);
    }
    else if (score >= 38) {
      setLevel(9);
      setDone(1000);
    }
    else if (score >= 25) {
      setLevel(8);
      setDone(1000);
    }
    else if (score >= 11) {
        setLevel(7);
        setDone(1000);
    }
    else if (done === 0 || done === 1) {
      if (score >= 6) {
        setLevel(6);
        setDone(1000);
      }
      else {
        setLevel(5);
        setDone(1);
      }
    }
    else {
      setLevel(6);
      setDone(1000);
    }
    window.scrollTo({ top: 500, left: 0 });
  };

  useEffect(() => {
  onSubmit();
}, []);

  return (
    <div>
      <h1>Level Assessment</h1>
      <p>Partial points are available for questions involving multiple moves.</p>
      <div>
        <label>In this position, White has a forced checkmate in seven moves, assuming Black plays the best moves.<br></br>List out, in the order they should be played, the seven moves that White plays (ignoring Black's moves). <br></br>Please separate each move with exactly one space and do not include move numbers.</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer1}
								onChange={e => setAnswer1(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/gz66Uiq9" frameborder="0"></iframe>
      </div>

      <div class="movedown">
      <label>In this position, White can force a draw, assuming that Black plays the best moves.<br></br>List out, in the order they should be played, the three moves that White must play to force this draw.<br></br>(Do not include move numbers)</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer2}
								onChange={e => setAnswer2(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/T9Gc5oMn" frameborder="0"></iframe>
      </div>

      <div class="movedown">
      <label>What is the best move for White in the following position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer3}
								onChange={e => setAnswer3(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/yMIgAY2N" frameborder="0"></iframe>
      </div>

      <div class="movedown">
      <label>White can win significant material in this position.<br></br>After White plays the second move, there are multiple logical responses for Black that do not lose the queen immediately.<br></br>List out all of the possible variations until White wins the Black queen, including Black's best responses to each move.<br></br>Please separate each move with a space but do not number the moves. <br></br>Please separate each variation with a comma followed by a space.<br></br>Variations can be in any order but all variations should start from the given position.<br></br>(Example: Ne7 Qxe7 Rb1, Ne7 Qb6 Rc6)</label>
        <div class = "la-container-long">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer4}
								onChange={e => setAnswer4(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/vly92bPN" frameborder="0"></iframe>
      </div>
      <div class="movedown">
      <label>It is White to move. Is this position a win for White, a draw, or a win for Black?</label>
      <div className="options">
        <div
          className={`option ${answer5 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect5('a')}
        >
          <span>Win for White</span>
        </div>
        <div
          className={`option ${answer5 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect5('b')}
        >
          <span>Draw</span>
        </div>
        <div
          className={`option ${answer5 === 'c' ? 'selected' : ''}`}
          onClick={() => handleSelect5('c')}
        >
          <span>Win for Black</span>
        </div>
        </div>
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/AKUPokh1" frameborder="0"></iframe>
      </div>
      <div class="movedown">
      <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer6}
								onChange={e => setAnswer6(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/cQxCmuhd" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer7}
								onChange={e => setAnswer7(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/npYP3Tjb" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>White has two winning moves in this position. What are these two moves? <br></br>(The order you list them does not matter, but please separate them by a space)</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer8}
								onChange={e => setAnswer8(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/eWnq1FFH" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer9}
								onChange={e => setAnswer9(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/jx6R6fqD" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>White can win the Rook in two moves. <br></br>What are the two moves that White needs to play to win the Rook, assuming that Black plays the best response?<br></br>Do not include Black's move, and separate the two moves by a space, entered in the order they should be played.</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer10}
								onChange={e => setAnswer10(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/YYx6i2GF" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer11}
								onChange={e => setAnswer11(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/W2YV6vdw" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer12}
								onChange={e => setAnswer12(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/Xruxwazg" frameborder="0"></iframe>
      </div>
      <button class = "la-button" onClick={onPrevious}>Previous</button>
      <button class = "la-button" onClick={handleSubmit}>Next</button>
    </div>
  );
};

export default LA3E;