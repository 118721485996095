import React, { useState }  from 'react';

const LA1 = ({setLevel, setRating, onNext }) => {
  const [answer, setAnswer] = useState('');

  const handleSelect = (value) => {
    setAnswer(value);
  };

  const handleSendVariable = (e) => {
    e.preventDefault();
    // Validate the form before performing any action
    if (answer === '') {
      // Display an error message or handle the validation error
      alert('Please select an option before submitting the form.');
      return;
    }
    else if (answer === 'Newbie') {
      setLevel(0);
    }
    handleNext();
  };

  const handleNext = () => {
    // Call the onNext callback function to trigger the Next action
    onNext();
  };

  return (
    <div>
      <h1>Level Assessment</h1>
      <div>
        <label>Do you know how the pieces in chess move?</label>
        <div className="options">
        <div
          className={`option ${answer === 'pass' ? 'selected' : ''}`}
          onClick={() => handleSelect('pass')}
        >
          <span>Yes</span>
        </div>
        <div
          className={`option ${answer === 'Newbie' ? 'selected' : ''}`}
          onClick={() => handleSelect('Newbie')}
        >
          <span>No</span>
        </div>
      </div>
        </div>
      <button class="la-button" id="lanext" onClick={handleSendVariable}>Next</button>
    </div>
  );
};

export default LA1;