import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useGetClassesQuery} from './classesApiSlice' // Imports redux function that gets the classes. Same format for users and students.
// Look at classesApiSlice for the things. Always useXXXX, where XXX is the function with first letter capital.
import ClassTableRow from './ClassTableRow.js'
// Data, is actual data. isLoading, isSuccess, isError is redux processes. Whatever it is will be true, automataically provided by redux. error is the actual error if there's an error.

const ClassesList = () => {
    // undefined MUST be there or stuff breaks!
    // Variable classes has all of the classes. Put the data into the classes variable.
    const { data: classes, isLoading, isSuccess, isError, error } = useGetClassesQuery(undefined, {
        pollingInterval: 60000, // How often it refreshes in milliseconds (every 60 seconds)
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true // Don't worry about these two
    })
    const [searchQuery, setSearchQuery] = useState("");
    let content;
    const navigate = useNavigate();
    const handleAdd = () => navigate(`/portal/add-class/`);

    if (isLoading) content = <p>Loading...</p>;
    if (isError) content = <p>{error.error}</p>;
    if (isSuccess) {
        const { ids } = classes;
        const tableContent = ids?.length
            ? ids.map((classId) => <ClassTableRow key={classId} classId={classId} searchQuery={searchQuery} />) : null;
            {/* map is like a for each loop, mapping through each class_id */}
        content = (
            <div class = "table-page">
                <br />
                <div class="right-align">
                    <h1>Classes List</h1>
                    <button class="add-class" onClick={handleAdd}>Add Class</button>
                </div>

                {/*<Link to="/portal/contests/new"><button>Create New Class</button></Link>*/}

                <input
                    type="text"
                    placeholder="Search Classes..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <br/><br/>
                
                <table>
                    <thead>
                        <tr>
                            <th>Level</th>
                            <th>Timing</th>
                            <th>Section/Teacher</th>
                            <th>Enrolled Students</th>
                            <th>Edit Class</th>
                            <th>Delete Class</th>
                        </tr>
                    </thead>
                    <tbody>{tableContent}</tbody>
                </table>
            </div>
        );
    }

    return content;
}

export default ClassesList