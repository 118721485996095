import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserById } from './usersApiSlice'

import EditUserForm from './EditUserForm'

const EditUser = () => {
	const { id } = useParams();
	const user = useSelector((state) => selectUserById(state, id));
	const content = user ? <EditUserForm user={user} editingAll={true}/> : <div>Loading...</div>
	return content;
}

export default EditUser