import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const classesAdaptor = createEntityAdapter({});

const initialState = classesAdaptor.getInitialState();

export const classesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClasses: builder.query({
            query: () => '/classes',
            validStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            keepUnusedDataFor: 60,
            transformResponse: (responseData) => {
                const loadedClasses = responseData.map((_class) => {
                    _class.id = _class._id;
                    return _class;
                });
                return classesAdaptor.setAll(initialState, loadedClasses);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Classes', id: 'LIST' },
                        ...result.ids.map((id) => ({ type: 'Class', id }))
                    ]
                } else {
                    return [{ type: 'Classes', id: 'LIST' }]
                }
            }
        }),

        addNewClass: builder.mutation({
            query: initialClassData => ({
                url: '/classes',
                method: 'POST',
                body: { ...initialClassData }
            }),
            invalidatesTags: [{ type: 'Class', id: 'LIST' }]
        }),

        updateClass: builder.mutation({
            query: initialClassData => ({
                url: '/classes',
                method: 'PATCH',
                body: { ...initialClassData }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Class', id: arg.id }]
        }),

        updateClassStudents: builder.mutation({
            query: ({ class_id, student_id, action }) => ({
                url: `/classes/${class_id}`,
                method: 'PATCH',
                body: { student_id, action }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Class', id: arg.id }]
        }),

        deleteClass: builder.mutation({
            query: ({ id }) => ({
                url: `/classes`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Class', id: arg.id }]
        })
    })
});

export const {
    useGetClassesQuery,
    useAddNewClassMutation,
    useUpdateClassMutation,
    useUpdateClassStudentsMutation,
    useDeleteClassMutation
} = classesApiSlice;

export const selectClassResult = classesApiSlice.endpoints.getClasses.select();

export const selectClassesData = createSelector(
    selectClassResult,
    classesResult => classesResult.data
);

export const {
    selectAll: selectAllClasses,
    selectById: selectClassById,
    selectIds: selectClassIds
} = classesAdaptor.getSelectors(state => selectClassesData(state) ?? initialState);