import { store } from '../../app/store';
import { usersApiSlice } from '../users/usersApiSlice';
import { studentsApiSlice } from '../students/studentsApiSlice';
import { classesApiSlice } from '../classes/classesApiSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {
    useEffect(() => {
        console.log('mounting...');
        const users = store.dispatch(usersApiSlice.endpoints.getUsers.initiate());
        const students = store.dispatch(studentsApiSlice.endpoints.getStudents.initiate());
        const classes = store.dispatch(classesApiSlice.endpoints.getClasses.initiate());

        return () => {
            console.log('unmounting...');
            users.unsubscribe();
            students.unsubscribe();
            classes.unsubscribe();
        }
    }, []);

    return <Outlet />;
}  

export default Prefetch;