import React from 'react'

const Missing404 = () => {
	return (
		<div>
			<h1>Error 404: Page Not Found</h1>
		</div>
	)
}

export default Missing404