import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import LA1 from './LA1'; // Import the LA1 component
import LA2 from './LA2'; // Import the LA2 component
import LA3B from './LA3B'; // Import the LA3 component
import LA3M from './LA3M'; // Import the LA3 component
import LA3A from './LA3A'; // Import the LA3 component
import LA3E from './LA3E'; // Import the LA3 component
import LAResults from './LAResults'; // Import the LA3 component

const LevelAssessmentHome = () => {
  const sendEmail = () => {
    var response = emailjs.send(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY,
      {
        to_email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        guardian_first_name: formData.guardianFirstName,
        guardian_last_name: formData.guardianLastName,
        level: receivedVariable,
        phone: formData.phoneNumber,
      }, process.env.REACT_APP_PUBLIC_KEY);
    console.log(response);
  };

  const [rating, setRating] = useState(400);
  const [level, setLevel] = useState(-1); // 1 = Beginner, 2 = Medium
  const [done, setDone] = useState(0); // 1 = goingDown, 2 = goingUp, 1000 = Submit
  const [currentPage, setCurrentPage] = useState(1);
  const [receivedVariable, setReceivedVariable] = useState("Newbie");

  useEffect(() => {
    if (currentPage >= 3) {
      if (rating <= 500) setLevel(1);
      else if (rating <= 600) setLevel(2);
      else if (rating <= 700) setLevel(3);
      else if (rating <= 800) setLevel(4);
      else if (rating <= 900) setLevel(5);
      else if (rating <= 1000) setLevel(6);
      else if (rating <= 1100) setLevel(7);
      else if (rating <= 1200) setLevel(8);
      else if (rating <= 1500) setLevel(9);
      else if (rating > 1500) setLevel(100); // Automaster
    }
  }, [rating, currentPage]);

  useEffect(() => {
    if (level === 0) {
      setReceivedVariable("Newbie");
    }
    else if (level === 1) {
      setReceivedVariable("Beginner");
    }
    else if (level === 2) {
      setReceivedVariable("Medium");
    }
    else if (level === 3) {
      setReceivedVariable("Advanced 1");
    }
    else if (level === 4) {
      setReceivedVariable("Advanced 2");
    }
    else if (level === 5) {
      setReceivedVariable("Advanced 3");
    }
    else if (level === 6) {
      setReceivedVariable("Expert 1");
    }
    else if (level === 7) {
      setReceivedVariable("Expert 2");
    }
    else if (level === 8) {
      setReceivedVariable("Expert 3");
    }
    else if (level === 9) {
      setReceivedVariable("Expert 4");
    }
    else if (level === 100) {
      setReceivedVariable("Master");
    }
    else if (level === -1) {
      setReceivedVariable("Error");
    }
  }, [level]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    guardianFirstName: '',
    guardianLastName: '',
    email: '',
    phoneNumber: '',
  });

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    setLevel(1);
    setDone(0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const navigate = useNavigate();

  const handleFinalSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('participantData', JSON.stringify(formData));
    sendEmail();
    navigate('/')
  }

  const handleSubmit = (e) => {
    if (done === 1000) {
      e.preventDefault();
      // Save the form data to localStorage (you can also use a state management library or send it to an API)
      localStorage.setItem('participantData', JSON.stringify(formData));
      navigate('/confirmation'); // Use navigate to go to the confirmation page
    }
  };

  return (
    <div>
      <header>
        <Link to="/"><img id="logo-link" src="./images/logo.png" alt="CCYC Logo" width="200" /></Link>
        <h2 id="title-assess">Level Assessment Tool</h2>
      </header>
      <form onSubmit={handleSubmit} class="login-container">
        <main class="la">
          <h1>Contact Information</h1>
          <div class="username-hi">
            <label>Participant First Name:   </label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
          </div>
          <div>
            <label>Participant Last Name:   </label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </div>
          <div>
            <label>Guardian First Name:   </label>
            <input type="text" name="guardianFirstName" value={formData.guardianFirstName} onChange={handleChange} required />
          </div>
          <div>
            <label>Guardian Last Name:   </label>
            <input type="text" name="guardianLastName" value={formData.guardianLastName} onChange={handleChange} required />
          </div>
          <div>
            <label>Email:   </label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div>
            <label>Phone Number:   </label>
            <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
          </div>
        </main>
        <div class="latool">
          {done !== 1000 && currentPage === 1 && <LA1 setLevel={setLevel} setRating={setRating} onNext={handleNext} />}
          {level !== 0 && done !== 1000 && currentPage === 2 && <LA2 setLevel={setLevel} rating={rating} setRating={setRating} onPrevious={handlePrevious} onNext={handleNext} />}
          {done !== 1000 && level === 1 && currentPage === 3 && <LA3B done={done} setDone={setDone} level={level} setLevel={setLevel} onPrevious={handlePrevious} onSubmit={handleSubmit} />}
          {done !== 1000 && level === 2 && currentPage === 3 && <LA3M done={done} setDone={setDone} level={level} setLevel={setLevel} onPrevious={handlePrevious} onSubmit={handleSubmit} />}
          {done !== 1000 && (3 <= level && level <= 5) && currentPage === 3 && <LA3A done={done} setDone={setDone} level={level} setLevel={setLevel} onPrevious={handlePrevious} onSubmit={handleSubmit} />}
          {done !== 1000 && (6 <= level && level <= 9) && currentPage === 3 && <LA3E done={done} setDone={setDone} level={level} setLevel={setLevel} onPrevious={handlePrevious} onSubmit={handleSubmit} />}
          {(done === 1000 || level === 0 || level === 100) && <LAResults level={receivedVariable} onPrevious={handlePrevious} onNext={handleFinalSubmit} />}
        </div>
      </form>
    </div>

  );
};

export default LevelAssessmentHome