import React, { useState, useEffect }  from 'react';

const LA3A = ({done, setDone, level, setLevel, onPrevious, onSubmit }) => {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [answer5, setAnswer5] = useState('');
  const [answer6, setAnswer6] = useState('');
  const [answer7, setAnswer7] = useState('');
  const [answer8, setAnswer8] = useState('');
  const [answer9, setAnswer9] = useState('');
  const [answer10, setAnswer10] = useState('');
  const [answer11, setAnswer11] = useState('');
  const [answer12, setAnswer12] = useState('');
  const [answer13, setAnswer13] = useState('');
  const [answer14, setAnswer14] = useState('');
  const [answer15, setAnswer15] = useState('');

  const [score, setScore] = useState(0);

  const handleSelect1 = (value) => {
    setAnswer1(value);
  };
  const handleSelect2 = (value) => {
    setAnswer2(value);
  };
  const handleSelect3 = (value) => {
    setAnswer3(value);
  };
  const handleSelect4 = (value) => {
    setAnswer4(value);
  };
  const handleSelect5 = (value) => {
    setAnswer5(value);
  };
  const handleSelect6 = (value) => {
    setAnswer6(value);
  };
  const handleSelect7 = (value) => {
    setAnswer7(value);
  };
  const handleSelect8 = (value) => {
    setAnswer8(value);
  };
  const handleSelect9 = (value) => {
    setAnswer9(value);
  };
  const handleSelect10 = (value) => {
    setAnswer10(value);
  };
  const handleSelect11 = (value) => {
    setAnswer11(value);
  };
  const handleSelect12 = (value) => {
    setAnswer12(value);
  };
  const handleSelect13 = (value) => {
    setAnswer13(value);
  };
  const handleSelect14 = (value) => {
    setAnswer14(value);
  };
  const handleSelect15 = (value) => {
    setAnswer15(value);
  };

  useEffect(() => {
  let newScore = 0;
  if (answer1 === 'Nb5' || answer1 === 'nb5') newScore++;
  if (answer2 === 'Rf8+' || answer2 === 'rf8+') newScore++;
  if (answer3 === 'Qxe6+' || answer3 === 'qxe6+' || answer3 === 'Qxe6' || answer3 === 'Qe6+' || answer3 === 'qxe6') newScore++;
  if (answer4 === 'Rb8' || answer4 === 'rb8') newScore++;
  if (answer5 === 'Ne2+' || answer5 === 'ne2+' || answer5 === 'Ne2' || answer5 === 'Qxc3+' || answer5 === 'qxc3+' || answer5 === 'Qxc3' || answer5 === 'Qc3+' || answer5 === 'qxc3') newScore++;
  if (answer6 === 'Be8' || answer6 === 'be8') newScore++;
  if (answer7 === 'Ne3' || answer7 === 'ne3') newScore++;
  if (answer8 === 'Bxc6' || answer8 === 'bxc6' || answer8 === 'Bc6') newScore++;
  if (answer9 === 'Bf3+' || answer9 === 'bf3+' || answer8 === 'Bf3') newScore++;
  if (answer10 === 'Qxg5' || answer10 === 'qxg5' || answer10 === 'Qg5') newScore++;
  if (answer11 === 'Ne6' || answer11 === 'ne6') newScore++;
  if (answer12 === 'Qb6+' || answer12 === 'qb6+' || answer12 === 'Qb6') newScore++;
  if (answer12 === 'Qb6+' || answer12 === 'qb6+' || answer12 === 'Qb6') newScore++;
  if (answer13 === 'Re2+' || answer13 === 're2+' || answer13 === 'Re2') newScore++;
  if (answer14 === 'b') newScore++;
  if (answer15 === 'Qg8' || answer15 === 'qg8+' || answer15 === 'Qg8+') newScore++;

  setScore(newScore);
}, [answer1, answer2, answer3, answer4, answer5, answer6, answer7, answer8, answer9, answer10, answer11, answer12, answer13, answer14, answer15]);

  const handleSubmit = () => {
    if (score >= 8) {
      if (done === 0 || done === 2) {
        setLevel(6);
        setDone(2);
      }
      else {
        setLevel(6);
        setDone(1000);
      }
    }
    else if (score >= 5) {
      setLevel(5);
      setDone(1000);
    }
    else if (score >= 2) {
      setLevel(4);
      setDone(1000);
    }
    else if (done === 0 || done === 1) {
      if (score >= 1) {
        setLevel(3)
        setDone(1000);
      }
      else {
        setLevel(2);
        setDone(1);
      }
    }
    else {
      setLevel(3);
      setDone(1000);
    }
    window.scrollTo({ top: 500, left: 0 });
  };

  useEffect(() => {
  onSubmit();
}, []);

  return (
    <div>
      <h1>Level Assessment</h1>
      <div>
        <label>What is the best move for White in this position??</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer1}
								onChange={e => setAnswer1(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/o0pM9bOm" frameborder="0"></iframe>
      </div>

      <div class="movedown">
      <label>If Black plays Qxe5 on the next move, what move can White play to force a checkmate?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer2}
								onChange={e => setAnswer2(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/axzRYHd1" frameborder="0"></iframe>
      </div>

      <div class="movedown">
      <label>If Black plays Kxf7 on the next move, what is the best move for White?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer3}
								onChange={e => setAnswer3(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/JRKXQOd1" frameborder="0"></iframe>
      </div>

      <div class="movedown">
      <label>What is the best move for Black in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer4}
								onChange={e => setAnswer4(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/lSngmsbK" frameborder="0"></iframe>
      </div>
      <div class="movedown">
      <label>What is one of the moves Black can play in this position to force a checkmate?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer5}
								onChange={e => setAnswer5(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/zQZtMN1g" frameborder="0"></iframe>
      </div>
      <div class="movedown">
      <label>What can White play in this position to win the pawn on g6?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer6}
								onChange={e => setAnswer6(e.target.value)}
							/>
						</div>
          </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/pBl2uUSa" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for Black in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer7}
								onChange={e => setAnswer7(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/nJVrCr6C" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer8}
								onChange={e => setAnswer8(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/plKOuhWA" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What can Black play in this position to force a checkmate?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer9}
								onChange={e => setAnswer9(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/ApWud6dn" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for Black in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer10}
								onChange={e => setAnswer10(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/mzsMptcK" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer11}
								onChange={e => setAnswer11(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/KrBx3B3A" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>What is the best move for Black in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer12}
								onChange={e => setAnswer12(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/JH8dWMXE" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>What move can Black play in this position to force a checkmate?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer13}
								onChange={e => setAnswer13(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/FIt2u3TE" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>It is White to play. Is this position winning for White or is it a draw?</label>
        <div className="options">
        <div
          className={`option ${answer14 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect14('a')}
        >
          <span>Winning for White</span>
        </div>
        <div
          className={`option ${answer14 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect14('b')}
        >
          <span>Draw</span>
        </div>
      </div>
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/tpKjSPqx" frameborder="0"></iframe>
      </div>
      <div class="movedown">
        <label>What is the best move for White in this position?</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer15}
								onChange={e => setAnswer15(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/69VcqtFN" frameborder="0"></iframe>
      </div>
      <button class = "la-button" onClick={onPrevious}>Previous</button>
      <button class = "la-button" onClick={handleSubmit}>Next</button>
    </div>
  );
};

export default LA3A;