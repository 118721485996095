import React, { useState, useEffect }  from 'react';

const LA3M = ({done, setDone, level, setLevel, onPrevious, onSubmit }) => {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [answer5, setAnswer5] = useState('');
  const [answer6, setAnswer6] = useState('');

  const [score, setScore] = useState(0);

  const handleSelect1 = (value) => {
    setAnswer1(value);
  };
  const handleSelect2 = (value) => {
    setAnswer2(value);
  };
  const handleSelect3 = (value) => {
    setAnswer3(value);
  };
  const handleSelect4 = (value) => {
    setAnswer4(value);
  };
  const handleSelect5 = (value) => {
    setAnswer5(value);
  };
  const handleSelect6 = (value) => {
    setAnswer6(value);
  };

  useEffect(() => {
  let newScore = 0;
  if (answer1 === 'b') newScore++;
  if (answer2 === 'b') newScore++;
  if (answer3 === 'e') newScore++;
  if (answer4 === 'Nf7+' || answer4 === 'Nf7' || answer4 === 'nf7+') newScore++;
  if (answer5 === 'a') newScore++;
  if (answer6 === 'a') newScore++;
  setScore(newScore);
}, [answer1, answer2, answer3, answer4, answer5, answer6]);

  const handleSubmit = () => {
    if (score >= 4) {
      if (done === 0 || done === 2) {
        setLevel(level+1);
        setDone(2);
      }
      else {
        setLevel(level+1);
        setDone(1000);
      }
    }
    else if (score >= 2) {
      setDone(1000);
    }
    else {
      if (done == 0 && score === 0) {
        setDone(1);
        setLevel(level-1);
      }
      else if (done === 2) {
        setDone(1000);
      }
      else if (done == 1 && score === 0) {
        setDone(1);
        setLevel(level-1);
      }
      else{
        setDone(1000);
      }
    }
    window.scrollTo({ top: 500, left: 0 });
  };

  useEffect(() => {
  onSubmit();
}, []);

  return (
    <div>
      <h1>Level Assessment</h1>
      <div>
        <label>Which of the following moves wins significant material for Black?</label>
        <div className="options">
        <div
          className={`option ${answer1 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect1('a')}
        >
          <span>Bh3</span>
        </div>
        <div
          className={`option ${answer1 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect1('b')}
        >
          <span>Bg4</span>
        </div>
        <div
          className={`option ${answer1 === 'c' ? 'selected' : ''}`}
          onClick={() => handleSelect1('c')}
        >
          <span>Bf5</span>
        </div>
        <div
          className={`option ${answer1 === 'd' ? 'selected' : ''}`}
          onClick={() => handleSelect1('d')}
        >
          <span>Be6</span>
        </div>
        <div
          className={`option ${answer1 === 'e' ? 'selected' : ''}`}
          onClick={() => handleSelect1('e')}
        >
          <span>Bd7</span>
        </div>
      </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/pmZAZodG" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>Which of the following moves wins significant material for White?</label>
        <div className="options">
        <div
          className={`option ${answer2 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect2('a')}
        >
          <span>Bf8+</span>
        </div>
        <div
          className={`option ${answer2 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect2('b')}
        >
          <span>Be3+</span>
        </div>
        <div
          className={`option ${answer2 === 'c' ? 'selected' : ''}`}
          onClick={() => handleSelect2('c')}
        >
          <span>Ra8</span>
        </div>
        <div
          className={`option ${answer2 === 'd' ? 'selected' : ''}`}
          onClick={() => handleSelect2('d')}
        >
          <span>Ra6</span>
        </div>
        <div
          className={`option ${answer2 === 'e' ? 'selected' : ''}`}
          onClick={() => handleSelect2('e')}
        >
          <span>h4</span>
        </div>
      </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/NSOYhuBC" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>What move will lead to a forced checkmate in 2 moves for White?</label>
        <div className="options">
        <div
          className={`option ${answer3 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect3('a')}
        >
          <span>Qxb7+</span>
        </div>
        <div
          className={`option ${answer3 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect3('b')}
        >
          <span>Nb5</span>
        </div>
        <div
          className={`option ${answer3 === 'c' ? 'selected' : ''}`}
          onClick={() => handleSelect3('c')}
        >
          <span>Nd5</span>
        </div>
        <div
          className={`option ${answer3 === 'd' ? 'selected' : ''}`}
          onClick={() => handleSelect3('d')}
        >
          <span>Nxb7</span>
        </div>
        <div
          className={`option ${answer3 === 'e' ? 'selected' : ''}`}
          onClick={() => handleSelect3('e')}
        >
          <span>Rd8</span>
        </div>
      </div>
      
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/Vt1n2JpD" frameborder="0"></iframe>
      </div>

      <div class="movedown">
        <label>What move can White play to force checkmate?<br></br>(Please type your answer using chess notation, without any spaces after or before your answer (e.g., Nxf3+))</label>
        <div class = "la-container">
						<div class = "la-text">
							<input
								type="text"
								id="answer"
								value={answer4}
								onChange={e => setAnswer4(e.target.value)}
							/>
						</div>
          </div>
          <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/uGW1yrYq" frameborder="0"></iframe>

      <div>
      <div class="movedown"></div>
        <label>Do you know the 5 types of draws?<br></br>(Stalemate, 50-Move Rule, Threefold Repetition, Insufficient Material, Draw by Agreement)</label>
        <div className="options">
        <div
          className={`option ${answer5 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect5('a')}
        >
          <span>Yes</span>
        </div>
        <div
          className={`option ${answer5 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect5('b')}
        >
          <span>No</span>
        </div>
      </div>
      </div>
      <div>
        <label>Who will win the trade if White plays cxd5 in this position?</label>
        <div className="options">
        <div
          className={`option ${answer6 === 'a' ? 'selected' : ''}`}
          onClick={() => handleSelect6('a')}
        >
          <span>White</span>
        </div>
        <div
          className={`option ${answer6 === 'b' ? 'selected' : ''}`}
          onClick={() => handleSelect6('b')}
        >
          <span>Black</span>
        </div>
      </div>
      <iframe width="600" height="371" src="https://lichess.org/study/embed/Ew33GJHV/aBkM94Ja" frameborder="0"></iframe>
      </div>


      </div>

      <button class = "la-button" onClick={onPrevious}>Previous</button>
      <button class = "la-button" onClick={handleSubmit}>Next</button>
    </div>
  );
};

export default LA3M;