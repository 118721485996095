const PortalFooter = () => {
    const footer = (
        <footer>
            <p>Chess Canada © 2023</p>
        </footer>
    )

    return footer;
}

export default PortalFooter