import React, { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetClassesQuery, useUpdateClassMutation } from '../classes/classesApiSlice'; // Import the class API query
import { useGetStudentsQuery } from '../students/studentsApiSlice';
import { selectUserById } from './usersApiSlice';
import Class from '../classes/Class';
import StudentAttendance from './StudentAttendance.js'

const Attendance = () => {
  const { id, username, role } = useAuth();
  const teacher = useSelector((state) => selectUserById(state, id));
  
  const [updateClass, {
    isLoading,
    isSuccess,
    isError,
    error
}] = useUpdateClassMutation();

  // Use the classes API query to fetch class data
  const { data: classes, isLoading: classesLoading, isSuccess: yay } = useGetClassesQuery(undefined, {
    pollingInterval: 100,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: students, isLoading: studentsLoading, isSuccess: otherYay } = useGetStudentsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [classIds, setClassIds] = useState([]);
  const [classEntities, setClassEntities] = useState({});
  const [selectedClass, setSelectedClass] = useState(''); // Initialize without a value
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [justRendered, setJustRendered] = useState(true);

  const handleClassSelect = (event) => {
    const selectedClassName = event.target.value;
    setSelectedClass(selectedClassName);

    // Find the corresponding class ID
    const classId = classIds.find(id => classEntities[id].group_identifier === selectedClassName);

    setSelectedClassId(classId);
  };

  useEffect(() => {
    const hi = async () => {
      if (yay && otherYay) {
        const {ids: classIds, entities: classEntities} = classes;
        const {ids, entities} = students;
        
        // Add all students to attendance array (with check that they already exist)
        for (const id of classIds) {
          let attendance_array = [...classEntities[id].attendance_data];
          // Remove all students that are not enrolled that are in attendance array
          for (let index = 0; index < attendance_array.length; index ++) {
            let enrolled = false;
            for (const studentId of ids) {
              if (studentId.toString() === attendance_array[index].slice(0, attendance_array[index].indexOf("."))) {
                enrolled = true;
              }
            }
            if (attendance_array[index][0] === "M") enrolled = true;
            if (!enrolled) {
              attendance_array.splice(index, 1)
            }
          }
  
          for (const studentId of ids) {
            // Check if already exists
            let go = true;
            for (const element of attendance_array) {
              if (element.split('.')[0] === studentId) {
                go = false; // already exists
              }
            }

            // Check if student is in class
            let studInClass = false;
            for (const element of classEntities[id].enrolled_students) {
              if (studentId === element) studInClass = true;
            }
  
            // Add if doesn't exist
            if (go && studInClass) {
              let string = studentId.toString()+".";
              let timing_string = classEntities[id].timing;
              timing_string = timing_string.slice(14);
              while (timing_string.length > 2 && timing_string.search(',') !== -1) {
                string = string + timing_string.slice(0, timing_string.search(',')) + "?.";
                timing_string = timing_string.slice(timing_string.search(',')+2);
              }
              string = string.slice(0, string.length-1);
              attendance_array.push(string);
            }
            
          }
          // Update
          const response = await updateClass ({
            id: id,
            group_identifier: classEntities[id].group_identifier,
            level: classEntities[id].level,
            teacher_id: classEntities[id].teacher_id,
            teacher: classEntities[id].teacher,
            timing: classEntities[id].timing,
            attendance_data: attendance_array
          });
        }
      }
    }

    hi();
    
  }, [yay, otherYay]);

  useEffect(() => {
    if (!classes || classesLoading || !teacher) {
      // Data is still loading or classes is undefined
      return;
    }

    if (justRendered) {
      const { ids, entities } = classes;
      const classNames = ids.map(id => entities[id].group_identifier);
      const teacherClasses = classNames.filter((className) => className.substr(4, 3).toLowerCase() === teacher.first_name.substr(0, 3).toLowerCase())
      let initialClassName = teacherClasses[0];
      const initialClassId = ids.find(id => entities[id].group_identifier === initialClassName);
      setSelectedClassId(initialClassId);
      setClassEntities(entities);
      setClassIds(ids);
      setJustRendered(false);
    }
  }, [classes, classesLoading, teacher]);

  if (!classes || classesLoading) {
    return (
      <div>Loading...</div>
    );
  }

  // Render the class names in a dropdown menu
  const classNames = classIds.map(id => classEntities[id].group_identifier);

  return (
    <div>
      <h1>Welcome, {username}</h1>
      <div>
        <div>
            <label htmlFor="classSelect">Select a Class:  </label>
            <select id="classSelect" onChange={handleClassSelect} value={selectedClass}>
                {teacher && classNames
                .filter((className) => className.substr(4, 3).toLowerCase() === teacher.first_name.substr(0, 3).toLowerCase())
                .map((className, index) => (
                    <option key={index} value={className}>
                    {className}
                    </option>
                ))}
            </select>
        </div>

      </div>
      <br />
      <div className="attendance-records">
        {/* Pass the selectedClassId to the Class component */}
        <StudentAttendance classId = {selectedClassId}/>
      </div>
    </div>
  );
};

export default Attendance;
