import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectStudentById } from '../students/studentsApiSlice';
import { useUpdateClassMutation } from '../classes/classesApiSlice';

function findNthDotIndex(attendance_data, n) {
    let dotCount = 0;
    for (let i = 0; i < attendance_data.length; i++) {
      if (attendance_data[i] === '.') {
        dotCount++;
        if (dotCount === n) {
          return i; // Found the i-th dot, return its index
        }
      }
    }
    return -1; // If not found, return -1
  }

const StudentAttendanceRow = ({ studentId, numDates, clss, classId}) => {
    const [isChecked, setIsChecked] = useState(new Array(numDates).fill("false"));
    const [initialRender, setInitialRender] = useState(true); // Track update in progress
    const student = useSelector((state) => selectStudentById(state, studentId));
    useEffect(() => {
        if (!student) return;
        if (initialRender) {
                let currentAttendance = [];
                let hi = "";
                for (const attendance of clss.attendance_data) {
                    if (studentId === attendance.slice(0, attendance.indexOf("."))) {
                        for (let i=0; i<numDates; i++) {
                            let index2;
                            if (i=== numDates-1) index2 = attendance.length;
                            else index2 = findNthDotIndex(attendance, i+2);
                            let index1 = findNthDotIndex(attendance, i+1);
                            if (attendance.slice(index2-1, index2) === "!") currentAttendance.push("true");
                            if (attendance.slice(index2-1, index2) === "?") currentAttendance.push("false");
                            if (attendance.slice(index2-1, index2) === "M") currentAttendance.push("make-up");
                        }
                    }
                }
                setIsChecked(currentAttendance);
                setInitialRender(false);
            }
      }, [student, clss.attendance_data, studentId, numDates]);

    const navigate = useNavigate();
    const [updateClass, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useUpdateClassMutation();

    const handleEdit = () => {
        navigate(`/portal/attendance/${studentId}`);
    }

    const handleCheckboxChange = async (e, j) => {
        e.preventDefault();
        const updatedIsChecked = [...isChecked];
        if (updatedIsChecked[j] === "true") updatedIsChecked[j] = "false";
        else if (updatedIsChecked[j] === "false") updatedIsChecked[j] = "true";
        setIsChecked(updatedIsChecked);
        let att = [...clss.attendance_data];
        let attendance_data = '';
        let index = -1;
        for (let i=0; i<att.length; i++) {
            let element = att[i];
            if (element.slice(0, element.indexOf(".")) === studentId) {
                attendance_data = element;
                index = i;
            }
        }
        if (updatedIsChecked[j] === "true") {
            let index1 = findNthDotIndex(attendance_data, j+1);
            let index2 = findNthDotIndex(attendance_data, j+2);
            if(index2 === -1) index2 = attendance_data.length;
            attendance_data = attendance_data.slice(0, index2-1) + "!" + attendance_data.slice(index2);
            
        } else if ((updatedIsChecked[j] === "false")){
            let index1 = findNthDotIndex(attendance_data, j+1);
            let index2 = findNthDotIndex(attendance_data, j+2);
            if(index2 === -1) index2 = attendance_data.length;
            attendance_data = attendance_data.slice(0, index2-1) + "?" + attendance_data.slice(index2);
        }
        att[index] = attendance_data;
        await updateClass({
            id: classId,
            group_identifier: clss.group_identifier,
            level: clss.level,
            teacher_id: clss.teacher_id,
            teacher: clss.teacher,
            timing: clss.timing,
            attendance_data: att
        });
    }

    const attendanceContent = [];
for (let i = 0; i < numDates; i++) {
    if (isChecked[i] === "false") {
        let bool = false;
        attendanceContent.push(
            <td className="cell_smallers" key={i}>
                <input
                    type="checkbox"
                    checked={bool}
                    onChange={(e) => handleCheckboxChange(e, i)}
                />
            </td>
        );
    } else if (isChecked[i] === "true") {
        let bool = true;
        attendanceContent.push(
            <td className="cell_smallers" key={i}>
                <input
                    type="checkbox"
                    checked={bool}
                    onChange={(e) => handleCheckboxChange(e, i)}
                />
            </td>
        );
    } else if (isChecked[i] === "make-up") {
        attendanceContent.push(
            <td className="cell_smallers" key={i}>
                M
            </td>
        );
    } else {
        // Handle other values here (if any)
        attendanceContent.push(
            <td className="cell_smallers" key={i}>
                {isChecked[i]}
            </td>
        );
        }
    }

    const content = (
        <tr>
            <td className="cell_smallers">{student.first_name}</td>
            <td className="cell_smallers">{student.last_name}</td>
            <td className="cell_smallers">{student.lichess_username}</td>
            <td className="cell_smallers">{student.recommended_level}</td>
            <td className="cell">
                <button className="button" onClick={handleEdit}>Edit</button>
            </td>
            {attendanceContent}
        </tr>
    );

    return content;
}

export default StudentAttendanceRow;
